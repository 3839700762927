import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Config } from '../../config';
import { Session } from 'src/app/pages/tag-workspace/store/models/build-assembly/buildassembly';
import { CommonApiService } from '../common-api.service';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class SessionManageService {
  private readonly config: Config;
  private readonly noLoaderOptions = {
    headers: new HttpHeaders({
      'no-spinner': 'true',
      'content-type': 'application/json',
      version: '1',
    }),
  };

  constructor(private readonly apiService: CommonApiService) {
    this.config = new Config();
  }

  public getSession(): Observable<Session> {
    return this.apiService.getJSON(this.config.CONFIGIT_GETSESSION_API, this.noLoaderOptions);
  }

  public removeSession(payLoad: string) {
    return this.apiService.post(
      this.config.CONFIGIT_REMOVESESSION_API,
      JSON.stringify(payLoad)
    );
  }

  saveSession(session: Session) {
    if (session?.newSession) {
    }
    sessionStorage.setItem('session', JSON.stringify(session));
  }
}
