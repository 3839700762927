import * as Types from '../../../../graphql/generated';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type SerialSearchQueryVariables = Types.Exact<{
  searchCriteria: Types.SerialCardSearchCriteria;
}>;


export type SerialSearchQuery = { __typename?: 'Query', serialCardCount: number, serialCards: Array<{ __typename?: 'SerialCard', serialNumber: string, tagNumber?: string | null, shipDateAsDate?: Date | null, orderLine: { __typename?: 'OrderLine', customerLineNumber?: string | null, repLineNumber?: string | null, oracleLineNumber: string, order: { __typename?: 'Order', repOrderNumber?: string | null, oracleOrderNumber: string, customerOrderNumber?: string | null } }, manufacturingLocation?: { __typename?: 'InventoryOrganization', orgCode?: string | null } | null, customerProject?: { __typename?: 'CustomerProject', projectNumber?: string | null, name?: string | null } | null, installedAddress?: { __typename?: 'Address', gdmAddressNumber?: any | null } | null, valve?: { __typename?: 'Component', name: string, products: Array<{ __typename?: 'Product', valveSize?: { __typename?: 'ProductAttributeValue', value?: string | null } | null }> } | null, actuatorComponent?: { __typename?: 'Component', name: string } | null, instrumentsComponent?: { __typename?: 'Component', name: string } | null }> };

export const SerialSearchDocument = gql`
    query SerialSearch($searchCriteria: SerialCardSearchCriteria!) {
  serialCards(searchCriteria: $searchCriteria, maxResults: 5000) {
    serialNumber
    tagNumber
    orderLine {
      customerLineNumber
      repLineNumber
      oracleLineNumber
      order {
        repOrderNumber
        oracleOrderNumber
        customerOrderNumber
      }
    }
    manufacturingLocation {
      orgCode
    }
    shipDateAsDate: shipDate
    customerProject {
      projectNumber
      name
    }
    installedAddress {
      gdmAddressNumber
    }
    valve {
      name
      products {
        valveSize: attributeValue(attributeName: "Valve Size") {
          value
        }
      }
    }
    actuatorComponent: actuator {
      name
    }
    instrumentsComponent: instruments {
      name
    }
  }
  serialCardCount(searchCriteria: $searchCriteria)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SerialSearchGQL extends Apollo.Query<SerialSearchQuery, SerialSearchQueryVariables> {
    document = SerialSearchDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }