export interface IItemRevision {
  allConstructions: IConstruction[];
  revisionId: number;
  revisionName: string;
  createdDate: string;
  constructions: IConstruction[];
  type: string;
  checked?: boolean;
  treeNodeId?: string | number;
  isCut?:boolean;
  isCopy?:boolean;
  isDuplicate?:boolean;
}

export interface IConstruction {
  version: number; // New Field to show construction revision
  constructionType: string; // New Field to show construction is Primary
  sequence: number; // New Field
  constructionId: number;
  constructionVersionId: number;
  userId: 1;
  groupId: number; // One version can have multiple constructions
  shortDescription: string;
  longDescription: string;
  longDescriptionOverride: string;
  serviceDescription: string;
  hashTags?: any;
  totalPrice: string;
  revisionDate: string;
  createdDate: string;
  lastUpdatedBy: number;
  lastUpdatedDate: string;
  isComplete: boolean;
  emailId: string;
  checked?: boolean;
  treeNodeId?: string | number;
  isCut?:boolean;
  isCopy?:boolean;
  isDuplicate?:boolean;
}
export interface IItem {
  allRevisions: IItemRevision[];
  hashItems: string;
  itemId: number;
  itemName: string;
  itemNames: IItemNames[];
  itemNumber: string;
  createdDate: string;
  lastUpdatedDate: string;
  hashTags: string;
  itemQuantity: number;
  sequenceNumber: number;
  itemRevisions: IItemRevision[];
  latestRevision: IItemRevision[];
  allRevision: IItemRevision[];
  latestPrior: IItemRevision[];
  type: string;
  checked: boolean;
  part: Part;
  itemType: number;
  serialNumber: string;
  treeNodeId?: string | number;
  isCut?:boolean;
  isCopy?:boolean;
  isDuplicate?:boolean;
  revisionType: RevisionType;
  isAlternate?: boolean;
}

export interface Part {  
    partId: number;
    sequence?: number | null;
    isComplete?: boolean | null;
    shortDescription: string;
    longDescription: string;
    longDescriptionOverride?: string;
    totalPrice: string;
    createdDate: string;
    lastUpdatedBy: number;
    emailId: string;
    lastUpdatedDate: string;

}
export interface IGroup {
  groupId: number;
  groupName: string;
  items: IItem[];
  type: string;
  checked?: boolean;
  viewGroups?: boolean;
  revisionType: RevisionType;
  treeNodeId?: string | number;
  isCut?:boolean;
  isCopy?:boolean;
  isDuplicate?:boolean;
  groupIndex?:number;
}
export enum RevisionType {
  ViewLatestRev = 'ViewLatestRevision',
  ViewAllRev = 'ViewAllRevisions',
  ViewPriorRev = 'ViewPriorRevisions',
  ViewAlternates = 'ViewAlternates',
}
export interface ITreeView {
  workspaceId: number;
  workspaceName: string;
  isApiCall?: boolean;
  groups: IGroup[];
}

export interface IGroupData {
  groupId: number;
  groupName: string;
}

export interface IWorkspaceTreeViewData {
  treeView: ITreeView;
  isSuccess: boolean;
  message: string;
}

export interface ISummaryView {
  hashItems: string;
  constructionVersionId: number | undefined;
  summaryViewId: number;
  item: string;
  itemId: number;
  revisionId: number;
  itemName: string;
  itemNamesArr: IItemNames[];
  revisionName: string;
  constructions?: IConstruction[];
  constructionType?: string;
  constructionTypeId?: number;
  groupName: string;
  groupId: number;
  itemQuantity: number;
  longDescription?: string;
  shortDescription?: string;
  constructionId?: number;
  totalPrice: string;
  hashTags: string;
  serviceDescription: string;
  conflicts: string;
  itemCreatedDate: string;
  revisionCreateDate: string;
  lastUpdatedDate: string;
  lastUpdatedBy: string;
  isComplete: boolean;
  itemType:string;
  serialNumber: string;
}

export interface IWorkspaceLevel {
  workspaceId: number;
  userId: string;
  itemIds: number[];
  groupId: number;
  LockLevel: number;
  IsPresentWorkspace?: boolean;
}
export interface IrenameGroup {
  groupId: number;
  workspaceId: number;
  groupName: string;
  userId: string;
}
export interface Iqtylevel {
  userId: string;
  itemId: number;
  newItemQuantity: number;
  workspaceId: number;
  itemNames: IItemNames[];
}
export interface IItemNames {
  id: number;
  name: string;
}
export interface IupdateItemlevel {
  userId?: string;
  itemId?: number;
  newItemName?: string;
  workspaceId?: number;
}

export interface IDeleteGrouplevel {
  userId: string;
  groupId: number;
  workspaceId: number;
}
export interface IupdatehashTag {
  userId: string;
  itemId: number;
  hashItem: string;
  workspaceId: number;
}
export interface ICreateGrouplevel {
  userId: string;
  workspaceId: number;
  groupName: string;
}
export interface ICreateItemlevel {
  groupId: number;
  workspaceId: number;
  itemName: string;
  sequenceNumber?: number | null;
  userId: string;
}
export interface IDeleteItemLevel {
  userId?: string;
  itemId?: number;
  workspaceId?: number;
}
export interface IDuplicateItemLevel {
  userId?: string;
  itemSequenceNumber?: any;
  workspaceId?: number;
  sourceGroupId?: number;
  destinationGroupId?: number;
  sourceItemId?: number;
}
export interface IDeleteRevLevel {
  userId?: string;
  itemId?: number;
  itemRevisionId?: number;
  workspaceId?: number;
}
export interface IUpdateItemGroup {
  userId?: string;
  itemId?: number;
  sourceGroupId?: number;
  destinationGroupId?: number;
  workspaceId?: number;
}

export interface IUpdateItemNumber {
  userId: string;
  itemId: number;
  itemNumber: string;
  workspaceId: number;
}

export interface IPasteConstructionLevel {
  userId?: string;
  workspaceId?: number;
  sourceConstructionId?: number;
  destinationRevisionId?: number;
  sourceItemId?: number;
  destinationItemId?: number;
  // itemId
}
export interface IRenameConstructionLevel {
  userId?: string;
  constructionId?: number;
  workspaceId?: number;
  newConstructionDescription?: string;
  itemId?: number;
}
export interface IDeleteConstructionLevel {
  userId?: string;
  constructionId?: number;
  workspaceId?: number;
  itemId?: number;
}

//create a new class for the Group Item itemRevision and construction
export class SelectedNode  {
  groupId: number;
  groupName: string;
  groupIndex:number;;
  items: IItem[];
  groups: IGroup[];
  type: string;
  checked?: boolean;
  viewGroups?: boolean;
  revisionType: RevisionType;
  treeNodeId?: string | number;
  level?: number;
  itemType?:number
  itemName?:string
  itemId?:number;
  itemRevisions?:IItemRevision[];
  revisionId?:number;
  revisionName:string;
  constructions?:IConstruction[];
  constructionId?:number;
  shortDescription?:string;
  acion?:string;
  constructionType?:string;
  isCopy?:boolean;
  isCut?:boolean;
  showAddTag?:boolean;
  isAlternate:boolean;
  constructor(){
    this.itemName = '';
  }
}
export interface TreeNode {
  groupId: number;
  groupName: string;
  items: IItem[];
  type: string;
  checked?: boolean;
  viewGroups?: boolean;
  revisionType: RevisionType;
  treeNodeId?: string | number;
  level?: number;
  itemType?:number
  itemName?:string
  itemId?:number;
  itemRevisions?:IItemRevision[];
  revisionId?:number;
  revisionName:string;
  constructions?:IConstruction[];
  constructionId?:number;
  acion?:string;
  constructionType?:string;
  isCut?:boolean;
}