<div [ngSwitch]="type">
    <ng-container *ngSwitchCase="'checkbox'">
        <label *ngFor="let option of optionList" class="inline-flex items-center cursor-pointer {{ wrapperClass }}">
            <input type="checkbox" [class]="inputClass" [name]="name" [value]="option.value" [checked]="option.checked">
            <span class="ml-2 text-sm font-normal text-gray-700">{{ option.label }}</span>
        </label>
    </ng-container>

    <ng-container *ngSwitchCase="'radio'">
        <label *ngFor="let option of optionList" class="inline-flex items-center cursor-pointer {{ wrapperClass }}">
            <input type="radio" [class]="inputClass" [name]="name" [value]="option.value" [checked]="option.checked">
            <span class="ml-2 text-sm font-normal text-gray-700" [class]="labelClass">{{ option.label }}</span>
        </label>
    </ng-container>

    <ng-container *ngSwitchCase="'textarea'">
        <textarea placeholder="{{ placeholder }}" [class]="inputClass" [name]="name" [value]="value"
            class="w-full resize-none px-2 py-1.5 text-sm bg-white border border-gray-300 rounded-lg outline-sky-200 placeholder:text-xs placeholder:font-normal placeholder:text-gray-500">
        </textarea>
    </ng-container>
    <ng-container *ngSwitchCase="'select'">
        <select [class]="inputClass" [name]="name" [value]="value" [disabled]="disabledState"
            class="w-full h-9 resize-none px-2 py-1.5 text-sm bg-white border border-gray-300 rounded-lg outline-sky-200 placeholder:text-xs placeholder:font-normal placeholder:text-gray-500">
            <option *ngFor="let option of options" [value]="option.value">
                {{ option.label }}
            </option>
        </select>
    </ng-container>
    <ng-container *ngSwitchDefault>
        <div [class]="wrapperClass" class="relative flex items-center disabled:cursor-not-allowed">
            <input [type]="type" [class]="inputClass" [name]="name" [value]="value" [placeholder]="placeholder"
                [attr.maxlength]="maxLength || null" (input)="onInputChange($event)" [ngClass]="{
                    'pl-8': iconClass && iconPosition === 'left',
                    'pr-8': iconClass && iconPosition === 'right',
                    '!border-red-500 outline-red-500': invalidState
                  }" class="w-full px-2 py-1.5 text-sm bg-white border border-gray-300 rounded-lg outline-sky-200" />
            <ng-container *ngIf="hasIcon && iconPosition === 'right'">
                <img *ngIf="icon" class="absolute right-2 top-[25%] cursor-pointer" [src]="icon" alt="Icon Image" />
            </ng-container>
        </div>
        <!-- Error message -->
        <p *ngIf="invalidState && errorMessage" class="!text-red-500 text-sm mt-[0.25rem]">
            {{ errorMessage }}
        </p>
    </ng-container>
</div>
<div class="mt-1 text-xs font-normal text-gray-500">{{ hint }}</div>