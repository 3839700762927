import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { Observable, catchError, finalize, tap } from 'rxjs';
import { CommonUtilityLibraryService } from '../library/common-utility-library.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private requests: number = 0;
  private spinnerHidden = false;
  private showSpinner = true;
  private spinnerDelay = 0;

  constructor(
    private readonly culibrary: CommonUtilityLibraryService,
    @Inject(OKTA_AUTH)
    private readonly oktaAuth: OktaAuth,
  ) {}

  intercept<T>(
    request: HttpRequest<T>,
    next: HttpHandler
  ): Observable<HttpEvent<T>> {
    this.spinnerHidden = false;
    this.showSpinner = request.headers.get('no-spinner') !== 'true';
    if (request.headers.get('spinner-delay')) {
      this.spinnerDelay = parseInt(request.headers.get('spinner-delay') || '0');
    }

    if (this.showSpinner || this.spinnerDelay > 0) {
      if (this.spinnerDelay > 0) {
        const startTick = Date.now();
        setTimeout(() => {
          const elapsed = Date.now() - startTick;
          if (elapsed > this.spinnerDelay && !this.spinnerHidden) {
            this.culibrary.show();
          }
        }, this.spinnerDelay);
      } else {
        this.culibrary.show();
      }
    }

    let authReq: HttpRequest<T> = request;
    const token = this.oktaAuth.getAccessToken();
    if (token) {
      if (!(request.body instanceof FormData)) {
        authReq = request.clone({
          setHeaders: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
          withCredentials: true,
        });
      } else {
        let headers = request.headers
          .delete('Content-Type')
          .set('Authorization', 'Bearer ' + token);

        if (headers.has('no-spinner')) {
          headers = headers.delete('no-spinner');
        }
        if (headers.has('spinner-delay')) {
          headers = headers.delete('spinner-delay');
        }

        authReq = request.clone({
          headers: headers,
          withCredentials: true,
        });
      }
    }

    this.requests++;
    return next.handle(authReq).pipe(
      tap(),
      finalize(() => {
        if (this.requests > 0) this.requests--;
        if (this.requests === 0) {
          this.spinnerHidden = true;
          this.culibrary.hide();
        }
      }),
      catchError((error: HttpErrorResponse) => {
        console.error(
          'HTTP Request Error:',
          error?.message,
          error?.status,
          error?.statusText,
          error?.url,
          error?.error
        );
        // if (error.status === 400) {
        //   this.router.navigate(['/error/bad-request']);
        //   throw (error);
        // }
        // if (error.status === 401) {
        //   this.router.navigate(['/error/access-denied']);
        //   throw (error);
        // }
        // if (error.status === 404) {
        //   this.router.navigate(['/error/not-found']);
        //   throw (error);
        // }
        // this.router.navigate([`/error/server-error/${error.status}`]);
        throw error;
      })
    );
  }
}
