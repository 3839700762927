import { Injectable } from '@angular/core';
import { DialogType } from 'src/app/shared/enums/common';
@Injectable({
  providedIn: 'root',
})

export class ConfirmationPopUpService {
  constructor() { }

  getButtonsByDialogType(dialogType: DialogType){
    let buttons: any[] = [];
    switch (dialogType) {
      case DialogType.SimpleDilaog:
        buttons = [
          {
            label: 'OK',
            value: 'OK',
            theme: 'primary'
          }
        ];
        break;
      case DialogType.ConfirmationDialog:
        buttons = [
          {
            label: 'No',
            value: 'No',
            theme: 'outline-primary'
          },
          {
            label: 'Yes',
            value: 'Yes',
            theme: 'primary'
          },
        ];
        break;
        case DialogType.SaveDialog:
        buttons = [
          {
            label: 'Cancel',
            value: 'isCancel',
            theme: 'outline-primary'
          },
          {
            label: 'Discard Changes',
            value: 'isLeave',
            theme: 'outline-primary'
          },
          {
            label: 'Save Changes',
            value: 'isSave',
            theme: 'primary'
          },
        ];
        break;
      default:
        break;
    } 
    return buttons;
  }
}