<div class="container font-inter">
    <div class="px-2">
        <div class="row">
            <p class="h1">Warning</p>
        </div>
        <div class="warning-content">
            <div class="iconSection">
                <img src="../../../../../assets/warning-alert.svg" alt="alert">
            </div>
            <div class="detail">
                {{data}}
            </div>
            
        </div>
    </div>

    <hr>

    <div class="row">
        <div class="col-btn buttons">
            <div mat-dialog-actions>
                <button mat-dialog-close class="btn-create" type="button">OK</button>
            </div>
        </div>
    </div>

</div>