import { EventEmitter, Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import { ValveSizingRequest } from "../../../models/sizevalve/valve.sizing.request";
import { ISummaryView } from "src/app/pages/workspace-treeview/model/project-tree-view.model";
import { TagWorkSpaceTab } from "src/app/shared/enums/tag-workspace";
import { ConfirmSelectParam, ConstructionSelectParam, BadgeInfo } from "../../../models/tag-workspace/tagworkspace.service.params";
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root',
})
export class TagWorkSpaceService {

  private valveSizingRequestSubject = new BehaviorSubject<ValveSizingRequest | null>(null);
  valveSizingRequest$ = this.valveSizingRequestSubject.asObservable();

  private isConfirmSelectionSubject = new BehaviorSubject<ConfirmSelectParam>({confirm: null, fromCatalog: false});
  isConfirmSelection$ = this.isConfirmSelectionSubject.asObservable();

  private constructionSelectSubject = new BehaviorSubject<ConstructionSelectParam | null>(null);
  constructionSelect$ = this.constructionSelectSubject.asObservable();

  private constructionRevertSubject = new BehaviorSubject<number | null>(null);
  constructionRevert$ = this.constructionRevertSubject.asObservable();

  private isPtValidationCancelledSubject = new BehaviorSubject<boolean | null>(null);
  isPtValidationCancelled$ = this.isPtValidationCancelledSubject.asObservable();  

  private tagDetailsSubject = new BehaviorSubject<ISummaryView | null>(null);
  tagDetails$ = this.tagDetailsSubject.asObservable();

  private tagWorkSpaceTabSubject = new BehaviorSubject<TagWorkSpaceTab | null>(null);
  tagWorkSpaceTab$ = this.tagWorkSpaceTabSubject.asObservable();

  private tagWorkSpaceBreadCrumbSubject = new BehaviorSubject<string | null>(null);
  tagWorkSpaceBreadCrumb$ = this.tagWorkSpaceBreadCrumbSubject.asObservable();

  private isSaveConfirmEnabledSubject = new BehaviorSubject<boolean>(false);

  private calculateButtonSource = new Subject<void | null>();
  calculateButtonClicked = this.calculateButtonSource.asObservable();

  public onManualSizeButtonClick$: EventEmitter<any>;

  private hideActionBarSubject = new BehaviorSubject<boolean | null>(true);
  hideActionBar$ = this.hideActionBarSubject.asObservable();

  private constructionLoadCompleteSubject = new BehaviorSubject<boolean | null>(false);
  constructionLoadComplete$ = this.constructionLoadCompleteSubject.asObservable();

  private readonly activateTabBadgeSubject = new Subject<BadgeInfo>();
  activateTabBadge$ = this.activateTabBadgeSubject.asObservable();

  private readonly navDisableOnDesignVarModifiedSubject = new BehaviorSubject<boolean>(false);
  private readonly designVarinValidationSubject = new BehaviorSubject<boolean>(false);
  private readonly defaultTagWorspaceTabSubject = new BehaviorSubject<TagWorkSpaceTab>(TagWorkSpaceTab.SizeValve);

  private _activeTabBadges: BadgeInfo[] = [];

  constructor() {
    this.onManualSizeButtonClick$ = new EventEmitter<any>();
  }

   updateValveSizingRequest(valveSizingRequest: ValveSizingRequest){
    this.valveSizingRequestSubject.next(valveSizingRequest);
  }

  set isConfirmSelection(value: ConfirmSelectParam) {
    this.isConfirmSelectionSubject.next(value);
  }

  set isPtValidationCancelled(value: boolean | null) {
    this.isPtValidationCancelledSubject.next(value);
  }

  set constructionSelect(value: ConstructionSelectParam | null) {
    this.constructionSelectSubject.next(value);
  }

  set constructionRevert(value: number | null) {
    this.constructionRevertSubject.next(value);
  }

  set tagDetails(data: ISummaryView | null) {
    this.tagDetailsSubject.next(data);
  }

  get tagDetails(): ISummaryView | null {
    return this.tagDetailsSubject.getValue();
  }

  set tagWorkSpaceTab(value: TagWorkSpaceTab | null) {
    this.tagWorkSpaceTabSubject.next(value);
    if (value) {
      this.activateTabBadge({
        tab: value,
        active: false,
        iconSrc: "",
        title: ""}, true);
    }
  }

  get tagWorkSpaceTab() {
    return this.tagWorkSpaceTabSubject.value;
  }

  set tagWorkSpaceBreadCrumb(value: string | null) {
    this.tagWorkSpaceBreadCrumbSubject.next(value);
  }

  get tagWorkSpaceBreadCrumb() {
    return this.tagWorkSpaceBreadCrumbSubject.getValue();
  }

  set isSaveConfirmEnabled(value: boolean) {
    this.isSaveConfirmEnabledSubject.next(value);
  }

  get isSaveConfirmEnabled(): boolean {
    return this.isSaveConfirmEnabledSubject.value;
  }

  calculateButtonClick() {
    this.calculateButtonSource.next();
  }

  get hideActionBar(): boolean | null {
    return this.hideActionBarSubject.value;
  }

  set hideActionBar(value: boolean | null) {
    this.hideActionBarSubject.next(value);
  }

  set constructionLoadComplete(value: boolean | null) {
    this.constructionLoadCompleteSubject.next(value);
  }

  set navDisableOnDesignVarModified(value: boolean) {
    this.navDisableOnDesignVarModifiedSubject.next(value);
  }

  get navDisableOnDesignVarModified(): boolean {
    return this.navDisableOnDesignVarModifiedSubject.value;
  }

  set designVarinValidation(value: boolean) {
    this.designVarinValidationSubject.next(value);
  }

  get designVarinValidation(): boolean {
    return this.designVarinValidationSubject.value;
  }

  set defaultTagWorspaceTab(value: TagWorkSpaceTab) {
    const newSubject = new BehaviorSubject<TagWorkSpaceTab | null>(null);
    newSubject.next(value);
    this.tagWorkSpaceTabSubject = newSubject;
    this.tagWorkSpaceTab$ = this.tagWorkSpaceTabSubject.asObservable();
    this.defaultTagWorspaceTabSubject.next(value);
  }
  get defaultTagWorspaceTab(): TagWorkSpaceTab {
    return this.defaultTagWorspaceTabSubject.value;
  }

  set activeTabBadges(value: BadgeInfo[]) {
    this._activeTabBadges = value;
  }
  get activeTabBadges(): BadgeInfo[] {
    return this._activeTabBadges;
  }

  getBadgeInfo(value: BadgeInfo | {
    tab: TagWorkSpaceTab,
    active: boolean,
    iconSrc: string,
    title: string}) {
    let badgeInfo: BadgeInfo;
    
    if (!('badgeInfoId' in value) && !('constructionId' in value) && !('module' in value)) {
      badgeInfo = {
          badgeInfoId: null,
          constructionId: null,
          module: 'Item Workspace',
          tab: value.tab,
          active: value.active,
          iconSrc: value.iconSrc,
          title: value.title,
      };
    } else {
      badgeInfo = value as BadgeInfo;
    }
    return badgeInfo;
  }

  activateTabBadge(value: BadgeInfo | {
        tab: TagWorkSpaceTab,
        active: boolean,
        iconSrc: string,
        title: string},
      fromTabChange: boolean = false) {

    const badgeInfo = this.getBadgeInfo(value);
    
    if ((this.tagWorkSpaceTab === badgeInfo.tab && !fromTabChange) || !badgeInfo.active) {
      // remove it
      this.removeBadge(badgeInfo, badgeInfo.active);
      return;
    }

    const existingBadge = this._activeTabBadges.find((badge) => badge.tab === badgeInfo.tab);
    if (!existingBadge) {
      // add it
      this._activeTabBadges.push(badgeInfo);
      this.activateTabBadgeSubject.next(badgeInfo);
    } else if (existingBadge.iconSrc !== badgeInfo.iconSrc) {
      // update it
      existingBadge.iconSrc = badgeInfo.iconSrc;
      existingBadge.title = badgeInfo.title;
      this.activateTabBadgeSubject.next(badgeInfo);
    }
  }

  removeBadge(badgeInfo: BadgeInfo, suppressMessage: boolean = false) {
    const initialCount = this._activeTabBadges.length;
    this._activeTabBadges = this._activeTabBadges.filter((badge) => badge.tab !== badgeInfo.tab);
  
    if (initialCount !== this._activeTabBadges.length && !suppressMessage) {
      this.activateTabBadgeSubject.next(badgeInfo);
    }
  }
  
  clearTagWorkSpaceState() {
    this.isConfirmSelectionSubject.next({ confirm: null, fromCatalog: false });
    this.valveSizingRequestSubject.next(null);
    this.constructionSelectSubject.next(null);
    this.constructionRevertSubject.next(null);
    this.tagWorkSpaceTabSubject.next(null);
    this.tagWorkSpaceBreadCrumbSubject.next(null);
    this.isSaveConfirmEnabledSubject.next(false);
    this.constructionLoadCompleteSubject.next(null);
    this.activeTabBadges = [];
  }
}
