import { GetWorkspaceRequest, Workspace, WorkspaceModel } from '../../models/workspace/workspace';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Kob } from '../../models/workspace/kob';
import { Status } from '../../models/status';
import { Config } from '../../config';
import { Injectable } from '@angular/core';

import { CommonApiService } from '../common-api.service';
import {IGroup, IItem, IItemRevision, IWorkspaceTreeViewData, RevisionType } from 'src/app/pages/workspace-treeview/model/project-tree-view.model';
import { KOB3 } from '../../models/workspace/kob3';
import { WorkSpace as PostAddToWorkspace, WorkspaceResponse } from '../../models/mro/add-to-workspace';
import { CopyWorkspaceResponse } from '../../models/workspace/copy-workspace';
import { Breadcrumb } from '../../models/breadcrumb';
import { ItemType } from '../../enums/tree-view-summary';


@Injectable({
  providedIn: 'root'
})
export class WorkspaceService { 
  private readonly config: Config;
  public workspace: WorkspaceModel = new WorkspaceModel();

  public kob3Response = new BehaviorSubject<KOB3[]>([]);

  public kob3Response$ = this.kob3Response.asObservable(); 
  public addTagsRowData = new Subject();
  addTagsRowDataObs = this.addTagsRowData.asObservable();

  public updateTagGroupData = new Subject();
  updateTagGroupDataObs = this.updateTagGroupData.asObservable();

  public pwsSearchInputValue = new BehaviorSubject('');
  pwsSearchInputValue$ = this.pwsSearchInputValue.asObservable();

  public updateTreeViewSummary = new BehaviorSubject<IWorkspaceTreeViewData>({} as IWorkspaceTreeViewData);
  updateTreeViewSummary$ = this.updateTreeViewSummary.asObservable();

  routesName: string = '';

  breadcrumbs = new BehaviorSubject<Breadcrumb[]>([]);
  breadcrumbs$ = this.breadcrumbs.asObservable(); 

  public reloadSummaryData = new Subject();
  reloadSummaryData$ = this.reloadSummaryData.asObservable();

  constructor(private readonly apiService: CommonApiService) {
    this.config = new Config();
  }

  public getWorkspaceDetailByUserId(projectRequest: GetWorkspaceRequest): Observable<Workspace[]> {
    return this.apiService.post(this.config.GET_WORKSPACES_API, projectRequest);
  }

  getKOBDetails(): Observable<Kob[]> {
    return this.apiService.getStaticData(this.config.GET_KOB_LIST_API);
  }
  getKOB3Details(): Observable<KOB3[]> {
    return this.apiService.getStaticData(this.config.GET_KOB3_LIST_API);
  }

  getKOB3Response(data: KOB3[]) {
    this.kob3Response.next(data);
  }

  setBreadcrumbs(data: Breadcrumb[]) {
    this.breadcrumbs.next(data);
  }

  getProjectSummary(workspaceId: number, userId: number): Observable<IWorkspaceTreeViewData> {
    let url = `${this.config.GET_TREEVIEW_SUMMARY_API}?workspaceId=${workspaceId}&userId=${userId}`;
    return this.apiService.getJSON(url);
  }

  getWorkspaceStatusList(): Observable<Status[]> {
    return this.apiService.get(this.config.GET_WORKSPACE_STATUS_LIST_API);
  }

  createWorkspace(_workspace: string) {
    return this.apiService.post(this.config.CREATE_WORKSPACE_API, _workspace);
  }

  copyWorkspace(_workspace: string):Observable<CopyWorkspaceResponse> {
    return this.apiService.post(this.config.POST_COPY_WORKSPACE_API, _workspace);
  }

  deleteWorkspace(_workspace: object) {
    return this.apiService.delete(this.config.DELETE_WORKSPACE_API, _workspace);
  }

  updateWorkspace(data: any) {
    this.updateTagGroupData.next(data)
  }

  getAddItemsRowData(workspaceData: any) {
    this.addTagsRowData.next(workspaceData);
  }

  setWorkspaceDetails(data: Workspace){
    this.workspace = data;
  }

  setworkspaceName(workspaceName: string){
    if(this.workspace) this.workspace.workspaceName = workspaceName;
  }
  setworkspaceId(workspaceId: number){
    if(this.workspace) this.workspace.workspaceId = workspaceId;
  }

  getworkspaceName(){
    if(this.workspace) return this.workspace.workspaceName;
    return "";
  }

  public getworkspaceId(){
    if(this.workspace) return this.workspace.workspaceId;
    return 0;
  }

  public getCurrencyCode(): string{
    if(this.workspace) return this.workspace.currencyCode;
    return "";
  }

  getpwsSearchInputValue(value: string){
    this.pwsSearchInputValue.next(value);
  }

  setRedirectRoute(routeName:string){
    this.routesName = routeName;
  }

  getTreeViewSummaryData(treeViewData: IWorkspaceTreeViewData){
    if(treeViewData?.treeView)
      if(treeViewData.treeView.isApiCall){
        treeViewData.treeView.groups = this.setAllTreeCheck(treeViewData.treeView.groups,treeViewData.treeView.isApiCall);
      }else{
        treeViewData.treeView.groups = this.setAllTreeCheck(treeViewData.treeView.groups,false);
      }
    this.updateTreeViewSummary.next(treeViewData);
  }

  setAllTreeCheck(groups: IGroup[], isApiCall: boolean) {
    let groupIndex = 0;
    for (let group of groups) {
      group.groupIndex = groupIndex++;
      this.checkedCheckBox(group);
      group.items = [...group.items].sort((a: IItem, b: IItem) => a.sequenceNumber - b.sequenceNumber);
      this.processItems(group.items, isApiCall);
    }
    return groups;
  }

  private processItems(items: IItem[], isApiCall: boolean) {
    for (let item of items) {
      this.checkedCheckBox(item);
      this.checkItemRevisions(item, isApiCall);
      this.processItemRevisions(item, isApiCall);
    }
  }

  private processItemRevisions(item: IItem, isApiCall: boolean) {
    for (let itemRev of item.itemRevisions) {
      this.checkedCheckBox(itemRev);
      this.checkConstructions(itemRev, isApiCall);
      this.processConstructions(itemRev, isApiCall, item);
    }
  }

  private processConstructions(itemRev: IItemRevision, isApiCall: boolean, item: IItem) {
    for (let construction of itemRev.constructions) {
      if (isApiCall && item.latestRevision) {
        item.itemRevisions = item.latestRevision;
        item.revisionType = RevisionType.ViewLatestRev;
        item.isAlternate  = false
      }
      this.checkedCheckBox(construction);
    }
  }

  private checkedCheckBox(item: any) {
    item.checked = item.checked === undefined ? true : item.checked;
  }

  private checkItemRevisions(item: any,isApiCall:boolean) {
    if (item.itemRevisions.length > 0 && isApiCall) {
      const sortedArray = item.itemRevisions.toSorted((a: IItemRevision, b: IItemRevision) => b.revisionId - a.revisionId);
      item.allRevisions = sortedArray;
      item.latestPrior = sortedArray.slice(0, 2);
      item.latestRevision = sortedArray.slice(0, 1);
    }

  }

  private checkConstructions(item: any,isApiCall:boolean) {
    if (item.constructions.length > 0 && isApiCall) {
      item.allConstructions = item.constructions;
      item.constructions = item.allConstructions.slice(0, 1);
    }
  }
  getWorkSpaceDetails(){
    if(this.workspace) return this.workspace;
    return null;
  }

  getWorkspaceGroupList(workspaceId: number): Observable<any> {
    return this.apiService.post(`${this.config.GET_WORKSPACE_GROUP_LIST}?workspaceId=${workspaceId}`,'');
  }

  postAddToWorkspace(request: PostAddToWorkspace): Observable<WorkspaceResponse> {
    return this.apiService.post(this.config.POST_ADDTOWORKSPACE, request);
  }
  postExistingWorkspace(request: PostAddToWorkspace): Observable<WorkspaceResponse> {
    return this.apiService.post(this.config.POST_EXISTING_WORKSPACE, request);
  }

  setCurrencyCode(currencyCode: string){
    this.workspace.currencyCode = currencyCode;
  }

  reloadSummaryViewData(){
    this.reloadSummaryData.next(true);
  }
}