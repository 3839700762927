import {Tag} from '../tag';
import {Group} from '../group';
import { WorkspaceType } from '../../enums/workspace.type';
export interface Workspace {
    workspaceId: number;
    workspaceName: string;
    endUser: string;
    epCs: string;
    endDestination: string;
    industry: string;
    installedBaseLocation: string;
    officeId: string;
    officeName: string;
    status: string;
    revisionNotation: string;
    countryCode: string;
    currencyCode: string;
    effectiveDate: string | null;
    worldArea: string;
    pricingOfficeId: string;
    nearestPlaceValue: number | null;
    roundingOption: string;
    currencyConversion: string | null;
    reportHeader: string;
    reportFooter: string;
    pricingUIType: string;
    isLocked: boolean;
    lockTypeId: number;
    lockedBy: string;
    lockedDate: string;
    kobId: string;
    kobName : string;
    userId :string;
    tags: Array <Tag>;
    groups: Array <Group>
    createdBy: number;
    createdDate: string;
    lastUpdatedBy: number;
    lastUpdatedDate: string;
    workspaceType:WorkspaceType;
    kob3CategoryId: string;
}
export class WorkspaceModel implements Workspace {
    workspaceName: string;
    workspaceId: number;
    constructor() {
      this.workspaceName = '';
      this.workspaceId = 0;
    }
    endUser: string;
    epCs: string;
    endDestination: string;
    industry: string;
    installedBaseLocation: string;
    officeId: string;
    officeName: string;
    status: string;
    revisionNotation: string;
    countryCode: string;
    currencyCode: string;
    effectiveDate: string | null;
    worldArea: string;
    pricingOfficeId: string;
    nearestPlaceValue: number | null;
    roundingOption: string;
    currencyConversion: string | null;
    reportHeader: string;
    reportFooter: string;
    pricingUIType: string;
    isLocked: boolean;
    lockTypeId: number;
    lockedBy: string;
    lockedDate: string;
    kobId: string;
    kobName: string;
    userId: string;
    tags: Tag[];
    groups: Group[];
    createdBy: number;
    createdDate: string;
    lastUpdatedBy: number;
    lastUpdatedDate: string;
    workspaceType: WorkspaceType;
    kob3CategoryId: string;
  }
export class GetWorkspaceRequest {
    constructor(){
        this.officeIds = [];
        this.searchQuery = "";
    }
    officeIds?: string[];
    searchQuery?: string;
    workspaceType?: WorkspaceType;
    workspaceId:number
}