import { ProductValidationState } from "src/app/shared/enums/buildassembly";
import { DialogType } from "src/app/shared/enums/common";

export class ConfirmationPopUp {
    productValidationState: ProductValidationState
    message: string;
    conflictMessage: string;
    dialogType: DialogType;
    wasPopWarningProducts: string[];
    cancelLabel: string;
    okLabel: string;

    //Simple Dialog
    isOk: boolean;

    //Confirmation Dialog
    isYes: boolean;
    isNo: boolean;

    //Save Dialog
    isSave: boolean;
    isCancel: boolean;
    isLeave: boolean;

    //ConfigIt Error
    isTurnOff: boolean;
    isTurnOffPT: boolean;
    isTurnOffPTResolve: boolean;
    isReplceValve: boolean;
    isReplaceProductFamily: boolean;
    isResolvePTManually: boolean;

    //ProductLineConflcit
    isResolve: boolean;
    isSetAsSpecial: boolean;
    isSetAsPTSpecial: boolean;

    //Model After
    isChange: boolean;
    ModelAfterName: string;

    isModelWarningMessage: boolean;

    constructor() {
        this.message = "";
        this.conflictMessage = "";
        this.dialogType = DialogType.SimpleDilaog;
        this.isOk = false;
        this.isYes = false;
        this.isNo = false;
        this.isSave = false;
        this.isLeave = false;
        this.isCancel = false;
        this.isTurnOff = false;
        this.isTurnOffPT = false;
        this.isTurnOffPTResolve = false;
        this.isResolve = false;
        this.isSetAsSpecial = false;
        this.isSetAsPTSpecial = false;
        this.isChange = false;
        this.isReplceValve = false;
        this.isResolvePTManually = false;
        this.isReplaceProductFamily = false;
        this.ModelAfterName = "";
        this.isModelWarningMessage = false;
        this.wasPopWarningProducts = [];
        this.cancelLabel =  "Cancel";
        this.okLabel = "Yes";        
    }
}