import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { SharedRoutingModule } from './shared-routing.module';
import { HeaderComponent } from './components/header/header.component';
import { MatMenuModule } from '@angular/material/menu';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AlertPopupComponent } from './components/popups/alert-popup/alert-popup.component';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserNavigationPopupComponent } from './components/popups/browser-navigation-popup/browser-navigation-popup.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { WarningPopupComponent } from './components/popups/warning-popup/warning-popup.component';
import { FooterComponent } from './components/footer/footer.component';

import { TabMenuModule } from 'primeng/tabmenu';
import { InputSwitchModule } from 'primeng/inputswitch';
import { MenubarModule } from 'primeng/menubar';
import { MenuModule } from 'primeng/menu';
import { TabViewModule } from 'primeng/tabview';
import { DropdownModule } from 'primeng/dropdown';
import { ButtonModule } from 'primeng/button';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from 'primeng/table';
import { DialogModule } from 'primeng/dialog';
import { TreeTableModule } from 'primeng/treetable';
import { MultiSelectModule } from 'primeng/multiselect';
import { TreeModule } from 'primeng/tree';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { CheckboxModule } from 'primeng/checkbox';
import { CalendarModule } from 'primeng/calendar';
import { ButtonRendererComponent } from './components/button-renderer/button-renderer.component';
import { ButtonThemeComponent } from './components/button-theme/button-theme.component';
import { SelectComponent } from './components/select/select.component';
import { CustomInputComponent } from './components/custom-input/custom-input.component';
import { ToggleComponent } from './components/toggle/toggle.component';
import { ActionBarComponent } from './components/action-bar/action-bar.component';
import { InputComponent } from './components/input/input.component';
import { ButtonComponent } from './components/button/button.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TabsComponent } from './components/tabs/tabs.component';
import { TabComponent } from './components/tabs/tab/tab.component';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { ChipsModule } from 'primeng/chips';
import { CardModule } from 'primeng/card';
import { RecentSearchComponent } from '../pages/mro/search/recent-search/recent-search.component';
import { ItemNamesPipe } from './pipes/item-names/item-names.pipe';
import { BulkSearchComponent } from '../pages/mro/search/bulk-search/bulk-search.component';
import { SelectButtonModule } from 'primeng/selectbutton';
import { AlertComponent } from './components/alert/alert.component';
import { DisplayFirstAndLastCharPipe } from "./pipes/display-first-and-last-char/display-first-and-last-char.pipe";
import { ConfirmationComponent } from '../pages/mro/popups/confirmation/confirmation.component';
@NgModule({
  declarations: [
    HeaderComponent,
    AlertPopupComponent,
    BrowserNavigationPopupComponent,
    WarningPopupComponent,
    FooterComponent,
    ButtonRendererComponent,
    ButtonThemeComponent,
    CustomInputComponent,
    ActionBarComponent,
    InputComponent,
    TabsComponent,
    TabComponent,
    RecentSearchComponent,
    ItemNamesPipe,
    BulkSearchComponent,
    ConfirmationComponent
  ],

  imports: [
    CommonModule,
    SharedRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatMenuModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatChipsModule,
    MatIconModule,
    MatTabsModule,
    MatSnackBarModule,
    MatDialogModule,
    MatProgressBarModule,
    MatInputModule,
    MatFormFieldModule,
    TabMenuModule,
    InputSwitchModule,
    MenuModule,
    MenubarModule,
    TabViewModule,
    DropdownModule,
    ButtonModule,
    AutoCompleteModule,
    InputTextModule,
    TableModule,
    DialogModule,
    TreeTableModule,
    TreeModule,
    CheckboxModule,
    DynamicDialogModule,
    MultiSelectModule,
    CalendarModule,
    FontAwesomeModule,
    SelectComponent,
    ToggleComponent,
    ButtonComponent,
    OverlayPanelModule,
    InputGroupModule,
    InputGroupAddonModule,
    ChipsModule,
    CardModule,
    SelectButtonModule,
    AlertComponent,
    // SpecsheetOverridesComponent,
    DisplayFirstAndLastCharPipe,
],

  exports: [
    CommonModule,
    HeaderComponent,
    FooterComponent,
    AlertPopupComponent,
    MatMenuModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatChipsModule,
    MatIconModule,
    MatTabsModule,
    MatSnackBarModule,
    MatDialogModule,
    MatProgressBarModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
    TabMenuModule,
    InputSwitchModule,
    MenuModule,
    MenubarModule,
    TabViewModule,
    DropdownModule,
    ButtonModule,
    AutoCompleteModule,
    InputTextModule,
    TableModule,
    DialogModule,
    TreeTableModule,
    TreeModule,
    CheckboxModule,
    DynamicDialogModule,
    MultiSelectModule,
    CalendarModule,
    ButtonThemeComponent,
    SelectComponent,
    CustomInputComponent,
    ToggleComponent,
    ActionBarComponent,
    InputComponent,
    ButtonComponent,
     TabsComponent,
    TabComponent,
    AlertComponent,
    // SpecsheetOverridesComponent,
    DisplayFirstAndLastCharPipe
  ],
})
export class SharedModule {}
