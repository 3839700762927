import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-alert',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './alert.component.html',
  styleUrl: './alert.component.scss',
})
export class AlertComponent {
  @Input() title: string = '';
  @Input() message: string | null = null;
  @Input() messageStyle: string = '';
  @Input() type: 'success' | 'warning' | 'note' | 'info' = 'success';
  @Input() closable: boolean = false;
  @Input() toggleable: boolean = false;
  @Output() closed = new EventEmitter<void>();

  isContentVisible: boolean = false;

  get bgColor(): string {
    return this.getBackgroundColor();
  }

  get iconClass(): string {
    return this.getIconClass();
  }

  get textClass(): string {
    return this.getTextColor();
  }

  private getBackgroundColor(): string {
    switch (this.type) {
      case 'success':
        return 'bg-[#d8f2e5]';
      case 'note':
        return 'bg-[#FEC]';
      case 'info':
        return 'bg-[#E5F4FF]';
      default:
        return 'bg-[#fde6e6]';
    }
  }

  private getIconClass(): string {
    switch (this.type) {
      case 'success':
        return 'fas fa-check-circle text-green-light';
      case 'note':
        return 'fas fa-triangle-exclamation text-[#CC8800]';
      case 'info':
        return 'fas fa-info-circle text-[#0052CC]';
      default:
        return 'fas fa-exclamation-circle text-[#C20A0A]';
    }
  }

  private getTextColor(): string {
    switch (this.type) {
      case 'success':
        return 'text-green-light';
      case 'note':
        return 'text-[#CC8800]';
      case 'info':
        return 'text-[#005999]';
      default:
        return 'text-[#C20A0A]';
    }
  }

  toggleContent() {
    this.isContentVisible = !this.isContentVisible;
  }

  closeNotification() {
    this.closed.emit(); // Emit an event when the notification is closed
  }

  isHtmlContent(message: string | null): boolean {
    return message != null && /<\/?[a-z][\s\S]*>/i.test(message); // Simple check for HTML tags
  }
}
