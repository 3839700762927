/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-const */
import { Injectable } from '@angular/core';
import { IConstruction, IGroup, IGroupData, ISummaryView, IItem, IItemRevision, ITreeView } from './model/project-tree-view.model';
import { CommonUtilityLibraryService } from 'src/app/shared/library/common-utility-library.service';
import { ItemType } from 'src/app/shared/enums/tree-view-summary';

@Injectable({
  providedIn: 'root'
})
export class WorkspaceTreeViewLibraryService {
  getLatestTreeViewSummary: ITreeView;

  constructor(private readonly commonLibrary: CommonUtilityLibraryService) {
    // intentionally
  }

  getSummaryViewData(_group: IGroup, _item: IItem, _rev: IItemRevision,_construction: IConstruction, id: number, ) {
    const summaryView = {} as ISummaryView;
    summaryView.summaryViewId = id;
    summaryView.item = _item.itemNumber;
    summaryView.itemId = _item.itemId;
    summaryView.revisionId = _rev.revisionId;
    if(_item.itemNames.length > 0){
      summaryView.itemName = _item.itemNames.map(item => item.name).join(',');
    }else{
      summaryView.itemName = _item.itemName.startsWith(_item.itemNumber+'_') ? _item.itemName.replace(_item.itemNumber+'_', '') : _item.itemName;
    }
    summaryView.itemNamesArr = _item.itemNames;
    summaryView.itemType = _item.itemType === ItemType.assembly?'Configured Item':_item.itemName;
    summaryView.serialNumber = _item.serialNumber;
    summaryView.constructions = _rev.constructions;
    summaryView.constructionType = _construction.constructionType?_construction.constructionType:'-';
    summaryView.constructionVersionId=_construction?.constructionVersionId;
    summaryView.constructionTypeId=0;
    summaryView.groupName = _group.groupName;
    summaryView.groupId = _group.groupId;
    summaryView.hashItems = _item.hashItems;
    summaryView.conflicts = "-";
    summaryView.serviceDescription = "-";
    summaryView.itemQuantity = _item.itemQuantity;
    summaryView.longDescription = _construction?.longDescriptionOverride ? _construction?.longDescriptionOverride : _construction?.longDescription;
    if ((!summaryView.longDescription || summaryView.longDescription === "") && !_item.part) {
      summaryView.longDescription = "UKN";
    } else if (_item.part) {
      summaryView.longDescription = _item.part.longDescription;
    }
    summaryView.shortDescription = _construction?.shortDescription;
    summaryView.constructionId = _construction?.constructionId;
    summaryView.totalPrice = _construction?.totalPrice;
    summaryView.isComplete = _construction?.isComplete;
    summaryView.serviceDescription = "-";
    summaryView.itemCreatedDate = this.commonLibrary.dateValueFormetter(_item.createdDate);
    summaryView.lastUpdatedDate = this.commonLibrary.dateValueFormetter(_item.lastUpdatedDate);
    summaryView.lastUpdatedBy=_construction?.emailId;
    summaryView.revisionName = _rev.revisionName?_rev.revisionName:"-";
    return summaryView;
  }


  getSummaryViewList(_treeViewData: ITreeView, selectedRevisions?: number[], selectedConstructions?: number[]) {
    const summaryViewList: ISummaryView[] = [];
    let id = 0;
    for (const _group of _treeViewData.groups) {
      this.processGroup(_group, summaryViewList, id);
    }
    return summaryViewList;
  }

  private processGroup(_group: IGroup, summaryViewList: ISummaryView[], id: number) {
    for (const _item of _group.items) {
      this.processItem(_group, _item, summaryViewList, id);
    }
  }

  private processItem(_group: IGroup, _item: IItem, summaryViewList: ISummaryView[], id: number) {
    for (const _rev of _item.itemRevisions) {
      this.processRevision(_group, _item, _rev, summaryViewList, id);
    }
    if (_item.checked && _item.part) {
      summaryViewList.push(this.getSummaryViewData(_group, _item, {} as IItemRevision, {} as IConstruction, ++id));
    }
  }

  private processRevision(_group: IGroup, _item: IItem, _rev: IItemRevision, summaryViewList: ISummaryView[], id: number) {
    for (const _construction of _rev.constructions) {
      if ((_construction.checked && _rev.checked) || _group.checked) {
        summaryViewList.push(this.getSummaryViewData(_group, _item, _rev, _construction, ++id));
      }
    }
  }

  getSummaryViewGroups(_treeViewData: ITreeView) {
    const groups: IGroupData[] = [];
    for (const _group of _treeViewData.groups) {
      groups.push({ groupName: _group.groupName, groupId: _group.groupId });
    }
    return groups;
  }
}
