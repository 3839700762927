import { Injectable } from '@angular/core';
import { CellClassParams, ColDef, ColumnMenuTab, ExcelExportParams, ExcelStyle, GetContextMenuItemsParams, GridApi, GridOptions, GridReadyEvent, ITextFilterParams, MenuItemDef } from 'ag-grid-community';
import { ExportType } from '../../enums/mro/export-type';
import { RowGroupDisplayType } from '../../enums/mro/ebom';


@Injectable({
  providedIn: 'root'
})
export class AgGridService {

  constructor() {
    this.onExportData = this.onExportData.bind(this);
    this.getContextMenuItems = this.getContextMenuItems.bind(this);
  }
  gridApi!: GridApi;
  
  searchResults: boolean = false;

  fileName: string = '';

  onGridReady<T>(params: GridReadyEvent<T>) {
    // Assign the API object from the provided params to the gridApi property
    this.gridApi = params.api;
    return this.gridApi;
  }

  gridOptions = <GridOptions> {
    // domLayout: 'autoHeight', // Set the domLayout property to 'autoHeight',
  };

  excelStyles: ExcelStyle[] = [
    {
      // The first style is for headers
      id: 'header',
      font: {
        // The font color for headers is white
        color: '#FFFFFF'
      },
      interior: {
        // The background color for headers is a shade of blue
        color: '#4472C4',
        // The background is solid
        pattern: 'Solid'
      },
      alignment: {
        horizontal: 'Left' // Align headers to the left
      }
    },
    {
      // The second style is for even rows
      id: 'evenExcelRow',
      interior: {
        // The background color for even rows is a light shade of blue
        color: '#DCE6F1',
        // The background is solid
        pattern: 'Solid'
      },
      alignment: {
        horizontal: 'Left' // Align even rows to the left
      }
    },
    {
      // The third style is for odd rows
      id: 'oddExcelRow',
      interior: {
        // The background color for odd rows is white
        color: '#FFFFFF',
        // The background is solid
        pattern: 'Solid'
      },
      alignment: {
        horizontal: 'Left' // Align odd rows to the left
      }
    }  
  ];
  autoGroupColumnDef(headerName: string, headerCheckboxSelection: boolean, checkboxSelection: boolean, suppressCount: boolean, cellRendererParamsCheckbox?: boolean): ColDef {
    return {
      headerName: headerName,
      flex: 1,
      headerCheckboxSelection: headerCheckboxSelection,
      checkboxSelection: checkboxSelection,
      cellRendererParams: {
        suppressCount: suppressCount,
        checkbox: cellRendererParamsCheckbox
      },
      minWidth: 200,
    };
  }

  autoGroupColumnDefMultiGroup(firstHeader: string, secondHeader: string, headerCheckboxSelection: boolean, checkboxSelection: boolean, suppressCount: boolean, cellRendererParamsCheckbox?: boolean): ColDef {
    return {
      headerValueGetter: (params: any) => {
        params.colDef.headerTooltip = params.colDef.headerName === 'EM String'? 'Non-Standard EMs ( either Special or Process Coded ) are managed as OE or CSP items and are shown in red': '';
        return params.colDef.headerName === firstHeader ? firstHeader: secondHeader;
      },
     
      flex: 1,
      headerCheckboxSelection: headerCheckboxSelection,
      checkboxSelection: checkboxSelection,
      minWidth: 300,
      cellStyle:  (params: any) => {
        return { color: params.colDef.headerName === 'EM String' && params.node.group && params.node.allLeafChildren.some((child: any) => child.data.engineeringModuleType == 'OEMOD') ? '#D31245' : '#344051' };
      },
      cellRendererParams: {
        suppressCount: suppressCount,
        checkbox: cellRendererParamsCheckbox
      },
  }
  }
  public defaultColDef(menuTabs: ColumnMenuTab[]): ColDef {
    return {
      filter: true,
      flex: 1,
      autoHeight: true,
      resizable: true,
      menuTabs: menuTabs,
      suppressMovable: true,
      filterParams: {
        buttons: ['apply', 'cancel'],
        closeOnApply: true,
        excelMode: 'windows',
      } as ITextFilterParams,

      cellClassRules: {
        oddExcelRow: (params: CellClassParams) => {
          return (params.node.rowIndex ?? 0) % 2 == 1;
        },
  
        evenExcelRow: (params: CellClassParams) => {
          return (params.node.rowIndex ?? 0) % 2 == 0;
        },
      },
      headerClass: 'header',
    }
  }

  defaultExcelExportParams(name: string, showRowStripes: boolean, showColumnStripes: boolean, showFilterButton: boolean): ExcelExportParams {
    return {
      exportAsExcelTable: {
        name: name,
        showRowStripes: showRowStripes,
        showColumnStripes: showColumnStripes,
        showFilterButton: showFilterButton, // Enable the filter button in the Excel table
      }
    }
  }

  getContextMenuItems(params: GetContextMenuItemsParams): (string | MenuItemDef)[] {
    const menu:(string | MenuItemDef)[]= [];
    if (params) {
      menu.push('copy', 'copyWithHeaders','separator',
      {
        name: 'Export',
        icon: '<span class="ag-icon ag-icon-save" unselectable="on" role="presentation"></span>',
        subMenu: [
          {
            name: 'XLSX',
            icon: '<span class="ag-icon ag-icon-excel" unselectable="on" role="presentation"></span>',
            action: () => {
              this.onExportData.bind(this, ExportType.Excel)();
            },
          },
          {
            name: 'CSV',
            icon: '<span class="ag-icon ag-icon-csv" unselectable="on" role="presentation"></span>',
            action: () => {
              this.onExportData.bind(this, ExportType.CSV)();
            },
          },
        ],
      }
    );
  }
  if(params?.column?.getColDef()?.field === 'serialNumber' && this.searchResults){
    menu.splice(2,0,
      {
        name: 'Open in New Tab',
        action: () => {
          window.open(window.location.origin+'/deconstruct/'+params.value, '_blank');
        }
      }
    )
  }
  return menu;
  }

  getSheetName() {
    const parts = this.fileName.split("_");
    return parts.length > 2 ? parts.splice(2).join("") : 'Sheet1';
  }

  onExportData(extension: string, onlySelectedRow?: boolean, columnKeys?: string[]) {
    switch (extension) {
      case ExportType.Excel:
        this.gridApi.exportDataAsExcel(
          {
           onlySelected: onlySelectedRow,
           fileName: this.fileName,
           skipRowGroups: false,
           allColumns: false,
           sheetName: this.getSheetName(),
           columnKeys: this.removeSnoColumnExportExcel()
          });
        this.gridApi.setGridOption('groupDisplayType', RowGroupDisplayType.multipleColumns);
        break;
      case ExportType.CSV:
        this.gridApi.exportDataAsCsv({ fileName: this.fileName });
        break;
    }
  }

  removeSnoColumnExportExcel() {
    const allColumns = this.gridApi.getColumns();
    if (allColumns && allColumns.length > 1 ) {
      return allColumns.slice(1).map(column => column.getColId());
    }
    return [];
  }

  getFileName(tabList: {id: number, name: string }[], selectedTab: number) {
    return tabList.find((tab: {id: number, name: string }) => tab.id === selectedTab)?.name ?? '';
  }

  cleanUp() {
    this.searchResults = false;
  }
}
