<p-dialog
  [(visible)]="showConfirmPopup"
  [modal]="true"
  [style]="{ width: '40vw', minHeight: '20vh', margin: '10px' }"
  [draggable]="false"
  (onHide)="close()"
  [resizable]="false"
>
<ng-template pTemplate="header">
  <p class="text-lg mb-4 text-black font-semibold">Confirmation</p>
</ng-template>
<div class="search gap-2 p-2 min-h-[100px]">
  <div class="search">
    <div class="flex justify-between items-center px-3 py-[10px] my-1 bg-slate-100">
      <div class="search flex flex-col gap-1">
        <span class="text-black text-base ">Only the top 5,000 records of <b>{{totalRecordsCount}}</b> will be displayed. Would you like to adjust your search criteria to reduce the results? </span>
      </div>
    </div>
  </div>
</div>
<div class="search flex justify-end w-full px-2 gap-x-2.5 mt-1 text-sm">
  <button type="button"
    (click)="onCancel()"
    class="shrink-0 whitespace-nowrap px-3 py-2.5 rounded-lg justify-center items-center gap-2 inline-flex border border-gray-100 text-gray-700 !text-xs !2xl:text-sm font-semibold font-inter !bg-gray-100">
    No
  </button>
  <button type="button" theme="default"
  (click)="onConfirm()"
    class="shrink-0 whitespace-nowrap px-3 py-2.5 rounded-lg justify-center items-center gap-2 inline-flex text-white !text-xs !2xl:text-sm font-semibold font-inter !bg-green-primary border-green-primary hover:!bg-green-light duration-300 !transition-all hover:!transition-all hover:!border-green-light transiton-all">
    Yes
  </button>
</div>
</p-dialog>