import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-confirmation',
  standalone: false,
  templateUrl: './confirmation.component.html',
  styleUrl: './confirmation.component.scss',
})
export class ConfirmationComponent {
  @Input() totalRecordsCount: number;
  @Input() showConfirmPopup: boolean = false;
  @Output() navigationChange = new EventEmitter();
  buttonValue: string;

  constructor(private readonly router: Router) {}
  onCancel() {
    //should not go to search result page
    this.buttonValue = 'yes';
    this.showConfirmPopup = false;
  }
  onConfirm() {
    //should go to Search result page
    this.buttonValue = 'no';
    this.showConfirmPopup = false;
  }
  close() {
    if (this.buttonValue === 'yes') {
      this.showConfirmPopup = false;
      this.navigationChange.emit(false);
      this.router.navigate(['search']);
    }else if(this.buttonValue === 'no'){
      this.showConfirmPopup = false;
      this.navigationChange.emit(true);
    }
  }
}
