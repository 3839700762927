@if(displayDefault) {
  <div [ngClass]="bulk_search?'py-[0.4rem]':'py-[1rem]'" class="font-inter flex justify-between w-full gap-8 bg-blue text-xs 2xl:text-sm lg:w-full" style="margin-top: 52px;">
    <div class="w-full h-full">
      <div
        class="flex flex-col justify-between px-2 w-full gap-12 2xl:gap-48 lg:flex-row"
      >
        <div class="flex gap-2 xl:flex-row xl:flex-nowrap w-full">
          @if(showSearchInputType){
          <div class="relative w-full mx-2">
            @if(!bulk_search){
              <app-input
              *ngIf="!isSearchVisible"
              [placeholder]="placeholder"
              [hasIcon]="true"
              [icon]="!isSearchVisible ? searchIcon : closeIcon"
              [inputClassName]="
                !isSearchVisible
                  ? 'min-w-full xl:min-w-[35dvw] pr-8 search focus:!outline-none'
                  : 'min-w-full xl:min-w-[30dvw] pr-8 search focus:!outline-none !rounded-b-none'
              "
              (click)="showSearch(true)"
              (iconClicked)="
                !isSearchVisible ? showSearch(true) : closeSearchtab()
              "
              [leftSrc]="isSearchVisible ? searchIcon : ''"
              [inputValue]="input_data"
            ></app-input>
            <app-input
              *ngIf="isSearchVisible"
              [inputClassName]="
                'min-w-full xl:min-w-[30dvw] pr-8 search !focus:!outline-none !rounded-b-none'
              "
            ></app-input>
            }
        
            <div class="search" *ngIf="isSearchVisible" ngClass="bg-white">
              <div
              [ngClass]="
              bulk_search
                ? 'absolute left-0 z-50 flex flex-col w-full bg-white focus:!outline-none self-stretch h-fit rounded-t-md rounded-b-md border-neutral-200 scrollbar'
                : 'absolute left-0 z-50 rounded-t-md mt-[-2.6rem] flex flex-col w-full bg-white focus:!outline-none self-stretch h-fit rounded-b-md border-neutral-200 scrollbar min-h-[3rem]'"
                (click)="handleSearchClick($event)"
                >
              <form [formGroup]="searchDialogForm">
              
                  <div class="bg-white rounded-lg">
                   @if(!bulk_search){
                    <ng-container>
                      <img
                        class="absolute left-2 mt-2 w-5"
                        [src]="searchIcon"
                        alt="searchIcon"
                      />
                    </ng-container>
                    <input
                    class="input text-base pl-10 py-[7px] bg-white border-b rounded-t-lg justify-between gap-2 inline-flex w-full focus:!outline-none"
                    type="text"
                    [placeholder]="placeholder"
                    formControlName="searchText"
                  />
                  <ng-container>
                    <img src="assets/icons/x.svg" alt="icon" (click)="showSearch(false)" class=" absolute top-[9px] right-3 cursor-pointer w-5 hover:text-red-500">
                  </ng-container>
                   }
                  </div>
                  @if(!bulk_search) {
                  <div class="gap-2 p-2 bg-white rounded-b-md">
                    <div class="flex justify-between items-start px-3 py-[10px]">
                      <div
                        class="font-semibold text-sm text-black flex justify-between"
                      >
                        Start your search by selecting a primary parameter below:
                      </div>
                      <div
                  class="flex gap-2"
                  
                >
            
                  <button
                    type="submit"
                    [disabled]="!searchDialogForm.valid"
                    [ngClass]="{ 'btn-disabled': !searchDialogForm.valid || (shipStartDate && !shipEndDate) }"
                    class="shrink-0 whitespace-nowrap px-3 py-[9px] rounded-lg justify-center items-center gap-2 inline-flex text-white text-sm font-semibold font-inter !bg-green-primary border-green-primary hover:!bg-green-light duration-300 !transition-all hover:!transition-all hover:!border-green-light transiton-all" (click)="onApplySearch()"
                  >
                    Apply Search
                  </button>
                  <app-button
                        [label]="'Bulk Search'"
                        [theme]="'outline-green'"
                        (click)="bulkDisplay(true)"
                      >
                      </app-button>
                   
                </div>
                     
                    </div>
                    <div
                      class=" grid grid-flow-col gap-x-2.5 grid-cols-auto mb-2"
                    >
                      <div class="flex flex-col justify-center gap-2.5">
                        <div class="flex px-4 text-sm py-2 bg-gradient-to-b from-white to-[#F0F0F0] flex-wrap w-full gap-3">
                          @for (option of searchOptions; track option.value) {
                          <label
                            class="inline-flex items-center cursor-pointer gap-2 font-semibold"
                          >
                            <input
                              type="radio"
                              id="radio"
                              formControlName="searchField"
                              [value]="option.value"
                              (click)="switchSearchOption(option.value)"
                              class="radio text-[#3333]"
                            />
                            <span
                              class="ml-2 text-sm 2xl:text-base font-normal text-[#334155]"
                              class=""
                              >{{ option.label }}</span
                            >
                          </label>
                          }
                        </div>
                      </div>
                    </div>
                    @if (noConttentFound().length > 0) {
                    <div
                      class="flex p-3 m-3 items-start gap-3 self-stretch rounded-[10px] bg-[#FEE7E7]"
                    >
                      <div class="notify">
                        <img src="assets/icons/Warning_yellow.svg" alt="" />
                      </div>
                      <div class="flex flex-col w-full">
                        <div
                          class="self-stretch text-[#141C24] text-xs 2xl:text-sm font-semibold"
                        >
                          We couldn’t find any results for your search
                        </div>
                        <div
                          class="self-stretch text-[#344051] text-xs 2xl:text-sm font-normal"
                        >
                          Try searching again with different keyword(s) / filters
                        </div>
                        @if(itemTypes.SerialNo === radio_value){
                        <div class="flex justify-between items-center">
                          New Deconstructed view of Non-Oracle(Legacy) Serial Card
                          will be released in the future. For now, click download
                          option to view the old pdf format.
                          <app-button
                            [label]="'Serial Card'"
                            (click)="downloadSerialCardPdf()"
                            [theme]="'outline-green'"
                            [hasIcon]="true"
                            [iconSrc]="serialcardicon"
                            [iconPlacement]="'left'"
                            [iconSrc]="'pi pi-download'" [ico]="true"
                          ></app-button>
                        </div>
                        }
                      </div>
                      <div class="notify">
                        <img
                          (click)="onCloseNoResults()"
                          src="assets/icons/Close2.svg"
                          alt=""
                        />
                      </div>
                    </div>
                    }
                    <div class="w-full px-3">
                      <div
                        class="w-full px-2 py-1.5 rounded-lg bg-[#F9FAFB] justify-start items-start gap-1 inline-flex"
                      >
                        <div
                          class="px-3 text-xs 2xl:text-sm cursor-pointer py-2 rounded-lg justify-center items-center gap-2 flex"
                          [ngClass]="{
                            'bg-white shadow-sm transition-all':
                              activeTabIndex === 0,
                            hidden:
                              radio_value === itemTypes.SerialNo ||
                              radio_value === itemTypes.OracleOrderNo ||
                              radio_value === itemTypes.EMString ||
                              radio_value === itemTypes.Product
                          }"
                          (click)="setActiveTab(0)"
                        >
                          <div
                            class="flex font-bold text-xs text-[#344051] 2xl:text-sm leading-normal transition-all"
                            [class.font-bold]="activeTabIndex === 0"
                          >
                            <img src="assets/icons/Filter.svg" alt="FilterIcon" />
                            Additional Filters
                          </div>
                        </div>
                        <div
                          class="px-3 cursor-pointer py-2 rounded-lg justify-center items-center gap-2 flex"
                          [ngClass]="{
                          'bg-white shadow-sm transition-all': activeTabIndex === 1,
                        }"
                          (click)="setActiveTab(1)"
                        >
                          <fa-icon [icon]="faFile" class=""></fa-icon>
                          <div
                            class="font-semibold text-xs 2xl:text-sm leading-normal transition-all"
                            [class.font-bold]="activeTabIndex === 1"
                          >
                            Recent Searches
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                    [ngClass]="{ 'hidden': onHideAdditionalFilters(radio_value) }"
                    class="p-4 flex flex-col gap-2 ng-star-inserted h-full max-h-[500px] w-full overflow-hidden overflow-y-auto"
                    *ngIf="activeTabIndex === 0">
                    <div class="">
                      <div class="flex flex-wrap gap-2 justify-content-start panel-container text-sm">
                        @for (item of searchOptions; track $index) {
                          @if(itemTypesArray.includes(item.value) || (item.value === itemTypes.SiteNo && radio_value !== itemTypes.SiteName)){
                        <div class="flex flex-col gap-2" *ngIf="item.value !== radio_value">
                          <label for="icondisplay" class="font-semibold">{{item.label}}</label>
                          <div class="flex flex-row font-normal card" 
                          [ngClass]="{'disabled':(item.value === itemTypes.CustomerNo) && (selectedCustomerName || searchDialogForm.value.userEnteredCustomerName)||(item.value === itemTypes.SiteNo) && (selectedSiteName || searchDialogForm.value.userEnteredSiteName) }">
                            <input type="text" placeholder={{item.label}}  [(ngModel)]="item.typedValue"
                              class="p-2 rounded-md outline-none w-[13rem]" [ngModelOptions]="{standalone: true}" />
                            <i class="pi pi-plus search cursor-pointer pl-2 py-2 pr-3 outline-none" (click)="addMultipleRow(item)"
                              (click)="$index.toggle($event);"></i>
                            <p-overlayPanel #$index [appendTo]="'.panel-container'" [className]="'custom-panel'">
                              <div class="flex flex-col w-[14rem]">
                                <div>
                                  <div class="flex flex-col gap-2">
                                    <div *ngFor="let li of item.searchNumber; let i = index"
                                      class="flex flex-row items-center justify-between border border-slate-200 py-1 rounded-md">
                                      <input type="text" placeholder="Enter Value" [(ngModel)]="li.searchNumberValue"
                                        [ngModelOptions]="{standalone: true}" class="search p-1 text-sm outline-none" />
                                      @if(i === 0) {
                                      <p-button (click)="addItem(item.searchNumber!)"
                                        icon="pi pi-plus search p-1"></p-button>
                                      }
                                      @if(i !== 0) {
                                      <p-button (click)="deleteItem(item.searchNumber!,i)"
                                        icon="pi search pi-trash hover:text-red-500 p-1"></p-button>
                                      }
                                    </div>
                                    <div>
                                      <div class="flex justify-start gap-2 item-start">
                                        <button class="search w-full lg:p-1 2xl:p-2 h-full rounded-lg bg-slate-100 font-medium  text-xs 2xl:text-sm"
                                          (click)="applyMultiple($index,item)">Apply</button>
                                        <button class="search w-full lg:p-1 2xl:p-2 rounded-lg text-green-primary border border-green-primary text-xs 2xl:text-sm"
                                          (click)="closeOverlay($index)">Cancel</button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </p-overlayPanel>
                          </div>
                        </div>
                        }
                        <div class="flex flex-col gap-2 text-xs 2xl:text-sm w-[246px]" [ngClass]="{'disabled': searchOptions[6].typedValue}"
                          *ngIf="item.value === itemTypes.CustomerName && item.value !== radio_value && radio_value !== itemTypes.CustomerNo">
                          <label for="icondisplay" class="font-semibold">Customer Name</label>
                          <div class="card flex">
                            <p-dropdown 
                            [ngModelOptions]="{ standalone: true }"
                            [options]="customerNamesOptions" 
                            [(ngModel)]="selectedCustomerName" 
                            placeholder="Select" 
                            [showClear]="true"
                            optionLabel="value"
                            optionValue="type"
                            class="rounded-md !outline-none" 
                            autoWidth="false"
                            [filter]="true"
                            filterBy="name" 
                            />
                          </div>
                        </div>
                        <div class="flex flex-col gap-2" [ngClass]="{'disabled': searchOptions[6].typedValue}"
                          *ngIf="item.value === itemTypes.CustomerName && item.value !== radio_value && radio_value !== itemTypes.CustomerNo">
                          <label for="icondisplay" class="font-semibold text-white">Input Default</label>
                          <div class="flex justify-between font-normal card ">
                            <input formControlName="userEnteredCustomerName" type="text" placeholder="Input Default"
                              class="w-[15rem] p-2 rounded-md outline-none" />
                          </div>
                        </div>  
                        @if (item.value === itemTypes.SiteName && radio_value !== itemTypes.SiteNo && item.value !== radio_value) {
                          <div class="flex flex-col gap-2 text-xs 2xl:text-sm w-[246px]" [ngClass]="{'disabled': searchOptions[8].typedValue}">
                            <label for="icondisplay" class="font-semibold">Site Name</label>
  
                            <div class="card flex justify-content-center">
                              <p-dropdown
                                  [ngModelOptions]="{ standalone: true }"
                                  [options]="siteNamesOptions"
                                  [(ngModel)]="selectedSiteName"
                                  placeholder="Select" 
                                  [showClear]="true"
                                  optionLabel="value"
                                  optionValue="type"
                                  [filter]="true"
                                  filterBy="name" 
                                  class="rounded-md !outline-none"/>
                          </div>
  
                          </div>
                          <div class="flex flex-col gap-2" [ngClass]="{'disabled': searchOptions[8].typedValue}">
                            <label for="icondisplay" class="font-semibold text-white">Input Default</label>
                            <div class="card flex justify-between font-normal">
                              <input type="text" formControlName="userEnteredSiteName" placeholder="Enter Site Name" class="w-[15rem] p-2 rounded-md outline-none" />
                            </div>
                          </div>
                        }
                        }
                      </div>
                    </div>
                    <div class="">
                      <div class="flex gap-2 text-xs 2xl:text-sm ">
                        <div class="flex flex-col gap-2 w-[246px]">
                          <label for="icondisplay" class="font-semibold"
                            >Manufacturing Location</label
                          >
                          <div class="card flex justify-content-center">
                            <p-dropdown
                            [ngModelOptions]="{ standalone: true }"
                            optionValue="organizationCode"
                            [options]="mfgLoctionInfo"
                            [(ngModel)]="selectedLocation"
                            placeholder="Select"
                            [showClear]="true"
                            optionLabel="organizationName"
                            class="rounded-md !outline-none"
                            autoWidth="false"
                            [filter]="true"
                            filterBy="name" 
                            />
                        </div>
                        </div>
                        <div class="flex flex-col gap-2 w-[246px]">
                          <label for="icondisplay" class="font-semibold"
                            >Rep/Sales Office #</label
                          >
                          <div class="card flex justify-content-center">
                            <p-dropdown
                            [ngModelOptions]="{ standalone: true }"
                            optionValue="officeId"
                            [showClear]="true"
                            [options]="userOffices"
                            [(ngModel)]="selectedOfficeId"
                            placeholder="Select"
                            class="rounded-md !outline-none"
                            autoWidth="false"
                            optionLabel="concatenatedValue"
                            [filter]="true"
                            filterBy="name" 
                            >
                            <ng-template let-option pTemplate="item">
                              <ng-container>
                                  <div class="dropdown-item">
                                    {{option.officeId}} - {{option.officeName}} {{option.officeCity}}
                                    </div>
                              </ng-container>
                          </ng-template>
                          </p-dropdown>
                        </div>
                        </div>
                      
                      </div>
                    </div>
                    <div class="">
                      <div class="flex gap-2 flex-wrap text-xs 2xl:text-sm">
                        <div class="flex flex-col gap-2 w-[246px]">
                          <label for="icondisplay" class="font-semibold"
                            >Notes</label
                          >
                          <div class="card flex ">
                            <p-dropdown
                            [ngModelOptions]="{ standalone: true }"
                            [options]="noteTypesInfo"
                            [(ngModel)]="selectedNote"
                            placeholder="Select"
                            [showClear]="true"
                            class="rounded-md !outline-none"
                            autoWidth="false"
                            [filter]="true"
                            filterBy="name" 
                            />
                        </div>
                        </div>
                        <div class="flex flex-col gap-2 text-xs 2xl:text-sm w-[246px]">
                          <label for="icondisplay" class="font-semibold text-white">Note Name</label>
  
                          <div class="card flex justify-content-center">
                            <p-dropdown
                            [ngModelOptions]="{ standalone: true }"
                            optionValue="type"
                            [options]="noteNamesOptions" 
                            [(ngModel)]="selectedNoteType"
                            placeholder="Select"
                            optionLabel="value"
                            class="rounded-md !outline-none"
                            autoWidth="false"
                            [showClear]="true"
                            />
                        </div>
  
                        </div>
                        <div class="flex flex-col gap-2">
                          <label for="icondisplay" class="font-semibold text-white">Input Default</label>
                          <div class="card flex justify-between font-normal">
                            <input type="text" formControlName="userEnteredNote" placeholder="Input Default" class="w-[15rem] p-2 rounded-md outline-none" />
                          </div>
                        </div>
  
                      </div>
                    </div>
                    <div class="">
                      <div class="flex gap-2 flex-wrap text-xs 2xl:text-sm">
                        <div class="flex flex-col gap-2 w-[246px]">
                          <label for="icondisplay" class="font-semibold"
                            >Product Structure</label
                          >
                          <div class="card flex ">
                            <p-dropdown
                            [ngModelOptions]="{ standalone: true }"
                            optionValue="value"
                            [options]="productStructureOptions"
                            [(ngModel)]="selectedProduct"
                            placeholder="Select"
                            optionLabel="name"
                            class="rounded-md !outline-none"
                            [showClear]="true"
                            autoWidth="false"
                            />
                        </div>
                        </div>
                        <div class="flex flex-col gap-2">
                          <label for="icondisplay" class="font-semibold text-white">Input Default</label>
                          <div class="card flex justify-between font-normal">
                            <input type="text" formControlName="userEnteredProduct" placeholder="Input Default" class="w-[15rem] p-2 rounded-md outline-none" />
                          </div>
                        </div>
  
                      </div>
                    </div>
                    <div class="flex gap-2 text-xs 2xl:text-sm p-fluid">
                      <div class="flex flex-col gap-2 w-[15rem]">
                        <label for="icondisplay" class="font-semibold">
                          Ship Start Date
                        </label>
                        <p-calendar class="border border-slate-200 shipStartDate w-full !focus:outline-none" [(ngModel)]="shipStartDate"
                          dateFormat="dd-M-yy" [iconDisplay]="'input'" [showIcon]="true"
                          [ngModelOptions]="{ standalone: true }" [maxDate]="maxDate" inputId="icondisplay"
                          placeholder="Select" [readonlyInput]="true" [showButtonBar]="true"
                           />
                      </div>
                      <div class="flex flex-col gap-2 w-[15rem] h-fit shrink-0">
                        <label for="icondisplay" class="font-semibold">
                          Ship End Date
                        </label>
                        <p-calendar [ngClass]="{'invalid': shipStartDate && !shipEndDate }"
                          class="border border-slate-200 shipEndDate w-full !focus:outline-none" [(ngModel)]="shipEndDate" dateFormat="dd-M-yy"
                          [iconDisplay]="'input'" [showIcon]="true" [ngModelOptions]="{ standalone: true }"
                          [maxDate]="maxDate" inputId="icondisplay" placeholder="Select" [readonlyInput]="true"
                          [showButtonBar]="true"  />
                      </div>
                    </div>
                  </div>
  
                    @if(activeTabIndex == searchFilterType.RecentSearch &&
                    recentSearchResults.length) {
                    <app-recent-search
                      [searchResults]="recentSearchResults"
                      (recentSelected)="recentSelectedItem($event)"
                    ></app-recent-search>
                    }
                  </div>
                  }   
              </form>
              @if (bulk_search) {
                <app-bulk-search
                  [searchOptions]="searchOptions"
                  (bulkDisplay)="bulkDisplay($event)"
                  (displaySearch)="displaySearch($event)"
                ></app-bulk-search>
                }
            </div> 
            </div>
         
          </div>
        }
          @if(!showSearchInputType){
            <div class="relative w-6/12 mx-2">
              <app-custom-input
              [placeholder]="'Project ID, Project Name, EPC, Group, Tag, Office ID'"
              [iconPosition]="'right'"
              [value]="pwsSearch"
              (inputChange)="searchChange($event)"
              (keyup.enter)="search()"
              [icon]="searchIcon"
              [hasIcon]="true"
            ></app-custom-input>
            </div>
          }
          <app-button [label]="'Ask Mani'" [hasIcon]="true" [hasIconTemplate]="true" class="">
            <div appIconTemplate>
              <div
                class="w-6 bg-opacity-5 rounded-[5px] flex items-center justify-between relative group">
                <img [src]="sparkIcon" class="w-5 h-5 transition-all group-hover:scale-125 group-hover:transition-all"
                  alt="icon" />
              </div>
            </div>
          </app-button>
        </div>
  
        <div class="flex gap-2 w-fit">
          <app-button [label]="'Size'" [hasIcon]="true" [hasIconTemplate]="true" class="w-fit">
            <div appIconTemplate>
              <div
                class="w-6 bg-sky-800 bg-opacity-5 rounded-[5px] flex items-center justify-between relative group">
                <img [src]="sizeIcon" class="w-5 h-5  transition-all group-hover:scale-125 group-hover:transition-all"
                  alt="icon" />
              </div>
            </div>
          </app-button>
  
          <app-button [label]="'Configure'" [hasIconTemplate]="true" class="w-fit">
            <div appIconTemplate>
              <div
                class="w-6 bg-sky-800 bg-opacity-5 rounded-[5px] flex items-center justify-between relative group">
                <img [src]="configureIcon" class="w-[1.1rem] transition-all group-hover:scale-110 group-hover:transition-all"
                  alt="icon" />
              </div>
            </div>
          </app-button>
          @if(showSearchInputType){
          <app-button [label]="'Add Parts'" [hasIconTemplate]="true" [theme]="'outline'"
            class="w-fit" (click)="addParts()">
            <div appIconTemplate>
              <div
                class="w-6 bg-transparent rounded-[5px] flex items-center justify-between relative group">
                <img [src]="addIcon" class="w-5 transition-all group-hover:scale-110 group-hover:transition-all"
                  alt="icon" />
              </div>
            </div></app-button>
            
  
  
          <app-button [label]="'Check Inventory'"  [hasIconTemplate]="true" [theme]="'outline'"
            class="w-fit">
            <div appIconTemplate>
              <div
                class="w-6 bg-transparent rounded-[5px] flex items-center justify-between relative group">
                <img [src]="inventoryIcon" class="w-4 transition-all group-hover:scale-110 group-hover:transition-all"
                  alt="icon" />
              </div>
            </div></app-button>
          }
        </div>
      </div>
    </div>
  </div>
  }
 <app-confirmation 
 [showConfirmPopup]="showConfirmPopup"
 [totalRecordsCount]="totalRecordsCount"
 (navigationChange)="navigationChange($event)"
 ></app-confirmation>
