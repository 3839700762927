<div [ngClass]="bgColor" class="p-[0.75rem] w-full rounded-[10px] justify-start items-center gap-[0.75rem] inline-flex font-inter">
    <div>
        <i [ngClass]="iconClass" class="flex text-lg shrink-0"></i>
    </div>
    <div class="inline-flex flex-col items-start justify-start w-full">
        <div class="text-sm font-semibold leading-normal text-black font-inter">{{ title }}</div>
        <div *ngIf="message" [ngClass]="messageStyle" class="font-normal leading-tight text-black font-inter">
            <div *ngIf="isHtmlContent(message); else plainMessage" [innerHTML]="message"></div>
            <ng-template #plainMessage>{{ message }}</ng-template>
        </div>
    </div>
    <div *ngIf="closable" class="cursor-pointer" (click)="closeNotification()">
        <i [ngClass]="textClass" class="flex text-lg transition-all shrink-0 fas fa-times-circle hover:opacity-60 hover:transition-all"></i>
    </div>
    <div *ngIf="toggleable" class="cursor-pointer" (click)="toggleContent()">
        <i class="flex text-sm text-black transition-all shrink-0 fas"
            [ngClass]="isContentVisible ? 'fa-chevron-up' : 'fa-chevron-down'" hover:opacity-60
            hover:transition-all></i>
    </div>
</div>

<div *ngIf="toggleable && isContentVisible" class="w-full">
    <ng-content></ng-content>
</div>