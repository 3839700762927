import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Config } from 'src/app/shared/config';
import {
  IUpdateItemNumber,
  ICreateGrouplevel,
  ICreateItemlevel,
  IDeleteGrouplevel,
  IDeleteRevLevel,
  IDeleteItemLevel,
  IWorkspaceTreeViewData,
  IWorkspaceLevel,
  Iqtylevel,
  IrenameGroup,
  IupdatehashTag,
  IupdateItemlevel,
  IDeleteConstructionLevel,
  IPasteConstructionLevel,
  IRenameConstructionLevel,
} from '../model/project-tree-view.model';
import {
  CreateAlternate,
  PasteRevision,
} from 'src/app/shared/models/project-worksapce/payloads';
import { CommonApiService } from 'src/app/shared/services/common-api.service';

@Injectable({
  providedIn: 'root',
})
export class TreeViewSummaryService {
  private config: Config;
  public updateTreeViewItemNames = new Subject();
  updateItemsRowDataObs = this.updateTreeViewItemNames.asObservable();
  public updateSummaryData = new Subject();
  updateSummaryDataObs = this.updateSummaryData.asObservable();
  
  constructor(private apiService: CommonApiService) {
    this.config = new Config();
  }

  getUpdatedItems(data: any) {
    this.updateTreeViewItemNames.next(data);
  }

  getupdateSummaryData(summaryViewData: IWorkspaceTreeViewData) {
    this.updateSummaryData.next(summaryViewData);
  }

  createRevisions(data: IWorkspaceLevel): Observable<IWorkspaceTreeViewData> {
    return this.apiService.post(
      this.config.POST_WORKSPACE_CREATE_REVISIONS_API,
      data
    );
  }

  renameGroup(data: IrenameGroup): Observable<IWorkspaceTreeViewData> {
    return this.apiService.put(this.config.PUT_PROJECT_RENAME_GROUP_API, data);
  }

  updateQty(data: Iqtylevel): Observable<IWorkspaceTreeViewData> {
    return this.apiService.put(this.config.PUT_PROJECT_ITEM_QTY_API, data);
  }

  updateItemName(data: IupdateItemlevel) {
    return this.apiService.put(this.config.PUT_PROJECT_ITEM_QTY_API, data);
  }

  updateItemNumber(data: IUpdateItemNumber): Observable<IWorkspaceTreeViewData> {
    return this.apiService.put(this.config.UPDATE_ITEM_NUMBER, data);
  }

  renameItem(data: IupdateItemlevel): Observable<IWorkspaceTreeViewData> {
    return this.apiService.put(this.config.PUT_RENAME_ITEM_API, data);
  }

  deleteGroup(deletedata: IDeleteGrouplevel): Observable<IWorkspaceTreeViewData> {
    return this.apiService.delete(this.config.DELETE_GROUP_API, deletedata);
  }

  deleteItem(data: IDeleteItemLevel): Observable<IWorkspaceTreeViewData> {
    return this.apiService.delete(this.config.DELETE_ITEM_API, data);
  }

  updateHashtag(data: IupdatehashTag):Observable<IWorkspaceTreeViewData> {
    return this.apiService.put(
      this.config.PUT_WORKSPACE_ITEM_UPDATE_HASHTAG_API,
      data
    );
  }

  createGroup(data: ICreateGrouplevel): Observable<IWorkspaceTreeViewData> {
    return this.apiService.post(
      this.config.POST_PROJECT_CREATE_NAME_GROUP_API,
      data
    );
  }

  createItem(data: ICreateItemlevel):Observable<IWorkspaceTreeViewData> {
    return this.apiService.post(this.config.POST_CREATE_ITEM_API, data);
  }

  duplicateItem(data: IupdateItemlevel) {
    return this.apiService.post(this.config.DUPLICATE_ITEM_API, data);
  }

  pasteItem(data: IupdateItemlevel):Observable<IWorkspaceTreeViewData> {
    return this.apiService.post(this.config.DUPLICATE_ITEM_API, data);
  }

  updateItemGroup(data: IupdateItemlevel):Observable<IWorkspaceTreeViewData> {
    return this.apiService.put(this.config.PUT_UPDATE_ITEM_GROUP, data);
  }

  pasteRev(data: PasteRevision):Observable<IWorkspaceTreeViewData> {
    return this.apiService.post(
      this.config.PASTE_WORKSPACE_PASTETE_REVISIONS_API,
      data
    );
  }

  deleteRev(data: IDeleteRevLevel) {
    return this.apiService.delete(
      this.config.DELETE_WORKSPACE_DELETE_REVISIONS_API,
      data
    );
  }

  pasteConstruction(data: IPasteConstructionLevel):Observable<IWorkspaceTreeViewData> {
    return this.apiService.put(this.config.PUT_PASTE_CONSTRUCTION_API, data);
  }

  renameConstruction(data: IRenameConstructionLevel):Observable<IWorkspaceTreeViewData> {
    return this.apiService.put(this.config.PUT_UPDATE_CONSTRUCTION_API, data);
  }

  deleteConstruction(data: IDeleteConstructionLevel):Observable<IWorkspaceTreeViewData> {
    return this.apiService.delete(this.config.DELETE_CONSTRUCTION_API, data);
  }

  setAsAlternateConstruction(data: CreateAlternate):Observable<IWorkspaceTreeViewData> {
    return this.apiService.put(
      this.config.SET_AS_PRIMARY_CONSTRUCTION_API,
      data
    );
  }

  createAlternateConstruction(data: CreateAlternate):Observable<IWorkspaceTreeViewData> {
    return this.apiService.post(
      this.config.CREATE_ALTERNATE_CONSTRUCTION_API,
      data
    );
  }
}
