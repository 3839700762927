<p-dialog
  header="Port Details"
  [(visible)]="isVisibleCreateTagModal"
  [closeIcon]="'true'"
  [modal]="true"
  [style]="{ width: '30vw' }"
  [draggable]="false"
  [resizable]="false"
  [showHeader]="false"
  [closeOnEscape]="false"
>
  <div
    class="flex px-2 py-0 flex-col items-start gap-5 self-stretch rounded-3xl bg-white"
  >
    <div class="flex items-start gap-5 shrink-0 self-stretch">
      <div class="flex flex-col items-start flex-[1_0_0]">
        <div class="flex w-full">
        <div class="text-lg font-semibold text-black">Create Tag Name</div>
        <div class="flex ml-auto cursor-pointer" (click)="close_popup()">  
        <img src="../../../../../assets/icons/close.svg" alt="close"/>
      </div>  
      </div>
      @if(deleteIconVisible) {  
      <div class="flex py-2 items-center self-stretch">
          <app-alert
            [type]="'note'"
            [messageStyle]="
              '!text-gray-900 !font-inter !text-base !font-semibold'
            "
            [message]="'Quantity does not match the number of tags.'"
            class="w-full"
          ></app-alert>
        </div>
      }
        <div class="container">
          <!-- Header Row -->
          <div class="row header">
            <div class="column">Tag</div>
            <div class="column">Tag Name</div>
          </div>

          <!-- Dynamic Rows -->
          <div [formGroup]="form">
            <div formArrayName="rows">
          @for(tag of rows.controls; track $index) {
          <div class="row" [formGroupName]="$index">
            <div class="column">{{ tag.get('tag')?.value }}</div>

            <!-- Conditional Rendering for Tag Name and Delete Button -->
            <div class="column tag-name">
              <div class="flex items-center input-wrapper">
                @if(!tag.get('isEditing')?.value) {
                <div class="editable w-full py-2" (click)="editTag($index)">
                  {{ tag.get('name')?.value }}
                  <div *ngIf="tag.get('name')?.invalid && tag.get('name')?.touched" class="flex text-red-500 ml-2">
                    <i class="fas fa-info-circle"></i> 
                    <span *ngIf="tag.get('name')?.hasError('required') ?? tag.get('name')?.hasError('minlength') ?? tag.get('name')?.hasError('maxlength')"></span>
                  </div>
                </div>
                } @else {
                <input
                 formControlName="name"
                  class="w-full px-2 py-1.5 text-sm bg-white border border-gray-300 rounded-lg outline-sky-200"
                />
                <!-- <input
                  formControlName="name"
                  class="input"
                /> -->
                } 
                
                @if(deleteIconVisible) {
                <div>
                  <i
                    class="fas fa-trash delete-icon"
                    (click)="deleteTag($index)"
                  ></i>
                </div>
                }
              </div>
            </div>
          </div>
          }
        </div>
        </div>
        </div>
        <div class="flex py-2 justify-end items-center gap-2 self-stretch">
          <app-button
            [theme]="'outline-primary'"
            [label]="'Clear All'"
            (click)="clearInputs()"
          ></app-button>
          <app-button
            [theme]="'primary'"
            [disabled]="form.invalid"
            [label]="'Save'"
            (click)="saveData()"
          ></app-button>
        </div>
      </div>
    </div>
  </div>
</p-dialog>
