export enum TagWorkSpaceTab {
    ProcessCondition = 'Process Condition',
    BuildAssembly = 'Build Assembly',
    SizeValve = 'Size Valve',
    SizeActuator = 'Size Actuator',
}

export enum ConstructionType {
    Benchmark = 'Benchmark',
    Primary = 'Primary',
    Alternate = 'Alternate',
}

export enum TagWorkSpaceNavigation {
    SwitchingConstruction = 1,
    CreateAlternate = 2,
    DuplicateRevision = 3,
    ExitFromTagWorkSpace = 4,
    RevertConstruction = 5,
}

export enum SaveDialogAction {
    Save = 'Save',
    Leave = 'Leave',
    Cancel = 'Cancel'
}