<div>
  <div
    class="px-8 py-2.5 bg-white custom-shadow justify-end items-center relative"
  >
    <div class="w-[45%] ml-auto">
      <app-custom-input
        [placeholder]="
          'Search for Serials / Plant / Site / Order / PO / Tag / Project / Customers'
        "
      ></app-custom-input>
    </div>
  </div>
  <div class="p-[1rem]">
    <div class="flex flex-col gap-6 mb-[1rem]">
      <div class="justify-start w-full bg-white rounded-lg custom-shadow">
        <div class="flex w-full px-4 py-2 items-center self-stretch border-b border-gray-300 gap-x-[0.5rem]">
          <div
            class="flex rounded-3xl border-baseGrey items-center gap-2 py-2 px-2 transition-all border hover:cursor-pointer"
          >
            <div class="text-blue text-sm">All</div>
            <span
              class="text-xs flex font-normal bg-blue text-white w-[1.40rem] h-[1.40rem] px-2 py-0.5 rounded-[150px] justify-center items-center"
            >
              {{ totalSelectedItemCount }}
            </span>
            <span
              class="text-xs flex font-normal !bg-gray-400 !text-white px-2 py-0.5 rounded-full w-5 h-5 justify-center items-center hover:!bg-red-500"
            >
              <i class="fa-solid fa-times"></i>
            </span>
          </div>
          @if (groupsTab.length) { @for (group of groupsTab; track $index) {
          <div
            class="flex rounded-3xl border-baseGrey items-center gap-2 py-2 px-2 transition-all border hover:cursor-pointer"
          >
            <div class="text-blue text-sm">{{ group.groupName }}</div>
            <span
              class="text-xs flex font-normal bg-blue text-white w-[1.40rem] h-[1.40rem] px-2 py-0.5 rounded-[150px] justify-center items-center"
            >
              {{ group.items.length }}
            </span>
            <span
              class="text-xs flex font-normal !bg-gray-400 !text-white px-2 py-0.5 rounded-full w-5 h-5 justify-center items-center hover:!bg-red-500"
            >
              <i class="fa-solid fa-times" (click)="removeGroup(group)"></i>
            </span>
          </div>
          } }
        </div>
        <div class="flex items-center justify-between px-4 py-2.5 gap-x-4">
          <div class="flex gap-3">
            <label class="inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                class="check"
                name="summary"
                value="summary"
                [(ngModel)]="summaryChecked"
              />
              <span class="ml-2 text-sm font-normal text-black">Summary</span>
            </label>

            <label class="inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                class="check"
                name="Multitag View"
                value="Multitag View"
                [(ngModel)]="multitagViewChecked"
              />
              <span class="ml-2 text-sm font-normal text-black"
                >Multitag View</span
              >
            </label>

            <app-select
              [options]="selectOptions"
              [selectedValue]="selectedOption"
              [name]="'view_mode'"
              placeholder="Select View mode"
              [wrapperClasses]="'w-full !mb-0'"
              [showChevron]="true"
              [selectClasses]="'w-full'"
              [className]="'w-fit'"
              (valueChange)="onSelectChange($event)"
            >
            </app-select>

            <label class="inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                class="check"
                name="Process Condition"
                value="Process Condition"
              />
              <span class="ml-2 text-sm font-normal text-black"
                >Process Condition</span
              >
            </label>
          </div>

          <div class="flex gap-2">
            <app-button
              [label]="'Refresh'"
              [appendClassName]="'!border-gray-300 border '"
            >
            </app-button>
            <app-button
              [label]="'Download Attributes & EM'"
              [appendClassName]="'!border-gray-300 border '"
            >
            </app-button>
            <app-button
              [label]="'Process Data  Input'"
              [appendClassName]="' !border-gray-300 border '"
            >
            </app-button>
          </div>
        </div>
      </div>
    </div>

    <div class="justify-start w-fit bg-white rounded-lg mb-2.5 px-1 py-1">
      <div class="flex gap-2">
        <app-button
          [label]="'Overview'"
          [appendClassName]="
            '!bg-[#2d383f] !text-white hover:bg-[#2d383f] hover:!text-white font-semibold custom-py-2\.5 custom-px-5'
          "
          [hasIcon]="true" [iconPlacement]="'left'" [iconSrc]="'fa-solid fa-border-all'" [ico] = "true"
        >
        </app-button>
      </div>
    </div>
    <div style="height: calc(90vh - 10rem); width: 100%">
      <div class="ag-scroll" style="height: 100%; width: 100%">
        <div class="w-full overflow-auto" style="height: 99%">
          <ag-grid-angular
            style="width: 100%; height: 100%"
            class="ag-theme-quartz ag-theme-custom"
            [columnDefs]="columnDefs"
            [enableRangeSelection]="true"
            [rowData]="rowData"
            [animateRows]="true"
            (gridReady)="onGridReady($event)"
            [allowContextMenuWithControlKey]="true"
            [overlayNoRowsTemplate]="noRowsTemplate"
            [singleClickEdit]="true"
            [getContextMenuItems]="getContextMenuItems"
            (cellValueChanged)="onCellValueChanged($event)"
          ></ag-grid-angular>
        </div>
      </div>
    </div>
  </div>
  @if(isVisibleConfirmationModal){
  <app-confirmation-popup
    [modalData]="confirmationPopUpData"
    (closeConfirmationPupUp)="closeConfirmationPupUp($event)"
    [isVisibleConfirmationPopUp]="isVisibleConfirmationModal"
  >
  </app-confirmation-popup>
  }
</div>
@if(isVisibleCreateTagModal) {
<app-create-tag
[isVisibleCreateTagModal]="isVisibleCreateTagModal"
[tableData]="tableData"
(isVisibleCreateTagModalChange)="isVisibleCreateTagModalChange($event)"
[quantityValues]="quantityData"
></app-create-tag>
}
<p-toast key="summary-view"></p-toast>
