<!-- <nav class="top-nav navbar-expand"
  *ngIf="currentPage==='/project' || currentPage==='/workspace'">
  <div>
    <ul class="navbar-nav">
      <ng-container *ngIf="currentPage=='/project'">
        <li class="top-items-home"><a>Settings</a></li>
        <li class="top-items-home"><a>Contact Us</a></li>
        <li class="top-items-home"><a>Release Notes</a></li>
        <li class="top-itemswithImage"><a><img src="../../../../assets/help-icon.svg" alt="" class="home-item">Help</a>
        </li>
        <li class="top-itemswithImage"><a><img src="../../../../assets/Language-icon.svg" alt=""
              class="home-item">English</a></li>
        <li class="top-items-home"><a (click)="logoutClick()">Logout</a></li>
      </ng-container>
      <ng-container *ngIf="currentPage==='/workspace'">
        <li><a (click)="homeNav()"><img src="../../../../assets/home-icon.svg" alt="home" class="workspace-item"></a>
        </li>
        <li class="top-items"><a (click)="projectInfoNav()">Project Info</a></li>
        <li class="top-items"><a (click)="pricingSettingsNav()">Price Settings</a></li>
        <li class="top-items"><a (click)="uomDefaultsNav()">UOM Defaults</a></li>
        <li class="top-items"><a>Permissions</a></li>
      </ng-container>
    </ul>
  </div>

</nav> -->

<nav class="navbar navbar-expand" [style.top]="currentPage === '/workspace' || currentPage === '/project' ? '0px':'0px'"
  [style.position]="currentPage === '/item-workspace' ? 'relative' : 'fixed'">
  <div class="container-fluid !pr-0">
    <a class="navbar-brand cursor-pointer" (click)="logoClicked()">
      <img src="../../../../assets/logo/PrestoLogo.svg" alt="presto" class="h-auto">
    </a>
    <a class="navbar-brand">
      <img class="w-[81px] h-[51px]" src="../../../../assets/logo/emerson.png" alt="emerson">
    </a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav ms-auto mb-2 mb-lg-0 align-items-center">
        <ng-container *ngIf="currentPage === '/workspace'">
          <li class="nav-item"><a (click)="homeNav()">
              <img src="../../../../assets/home-icon2.svg" alt="home" class="workspace-item">
            </a>
          <li class="nav-item"><a class="nav-link" (click)="documentsAndDrawingsNav()">Documents & Drawings</a></li>
          <li class="nav-item"><a class="nav-link" (click)="pricingAndDiscountsNav()">Pricing & Discounts</a></li>
          <li class="nav-item"><a class="nav-link" (click)="projectInfoNav()">Project Info</a></li>
          <li class="nav-item"><a class="nav-link" (click)="pricingSettingsNav()">Price Settings</a></li>
          <li class="nav-item"><a class="nav-link" (click)="uomDefaultsNav()">UOM Defaults</a></li>
          <li class="nav-item"><a class="nav-link" (click)="transferToFF2Nav()">Transfer to FF2</a></li>
          <li class="nav-item"><a class="nav-link">Permissions</a></li>
        </ng-container>
        <ng-container *ngIf="currentPage === '/item-workspace'">
          <button id="projectWrkBtn">Back to Project Workspace</button>
        </ng-container>
        <ng-container *ngIf="currentPage !== '/mro' && currentPage !== '/deconstruct' && currentPage !== '/'">
          <div class="d-flex align-items-center" style="padding-left: 5px;">
            <div class="userIcon"
              [ngStyle]="{'display':currentPage === '/workspace' || currentPage === '/documents-drawings' || currentPage === '/pricing-discounts' || currentPage === '/settings/project-info' || currentPage === '/settings/uom-defaults' || currentPage === '/settings/pricing-settings'|| currentPage === '/item-workspace' ? 'none':'flex'}">
              <!-- <img src="../../../../assets/user-icon-green.svg" alt="" class="person" [matMenuTriggerFor]="profiledropdown"> -->
              {{userName | displayFirstAndLastChar}}
            </div>
            <div class="user-info"
              [ngStyle]="{'display':currentPage === '/workspace' || currentPage === '/documents-drawings' || currentPage === '/transfer-to-ff2' || currentPage === '/pricing-discounts' || currentPage === '/settings/project-info' || currentPage === '/settings/uom-defaults' || currentPage === '/settings/pricing-settings' || currentPage === '/IUpdateItemGroup' ? 'none':'block'}">
              <p class="name">{{ userName }}</p>
              <p class="name">{{ officeName }}</p>
            </div>
            <mat-menu #profiledropdown="matMenu" class="">
              <button mat-menu-item class="text" (click)="logoutClick()">
                Logout
              </button>
            </mat-menu>
          </div>
        </ng-container>
      </ul>
    </div>
  </div>
</nav>
<nav id="breadcrumbNavbar" *ngIf="currentPage !== '/item-workspace'" aria-label="Breadcrumb" class="breadcrumb"
  [style.position]="currentPage === '/item-workspace' ? 'absolute' : 'fixed'"
  [style.top]="currentPage === '/workspace' || currentPage === '/project' ? '50px':currentPage === '/item-workspace' ? '0px':'50px'"
  [ngStyle]="{'display':currentPage ==='/workspace' || currentPage === '/documents-drawings' || currentPage === '/pricing-discounts' || currentPage === '/settings/project-info' || currentPage === '/settings/uom-defaults' || currentPage === '/settings/pricing-settings' || currentPage=='/item-workspace' ? 'block':'none'}">
  <ol class="breadcrumb">
    <li><a (click)="homeNav()">Home</a></li>
    <li><a [ngClass]="{'navBreadCrum' : currentPage === '/workspace'}" (click)="workspaceNav()">Project Workspace</a>
    </li>
    <li class="navBreadCrum" *ngIf="currentPage === '/documents-drawings'"><a>Documents & Drawings</a></li>
    <li class="navBreadCrum" *ngIf="currentPage === '/pricing-discounts'"><a>Pricing & Discounts</a></li>
    <li class="navBreadCrum" *ngIf="currentPage === '/settings/project-info'"><a>Project Info</a></li>
    <li class="navBreadCrum" *ngIf="currentPage === '/settings/uom-defaults'"><a>UOM Defaults</a></li>
    <li class="navBreadCrum" *ngIf="currentPage === '/settings/pricing-settings'"><a>Pricing Settings</a></li>    
  </ol>
</nav>