export const environment = {
  // Production
  appName: "PRESTO QA",
  production: true,
  test: false,
  envName: "",
  i18nPrefix: "",
  
  graphUrl: "https://presto-graph-qa.emerson.com/",
  prestoApiUrl: "https://presto-api-qa.emerson.com",

  oktaRedirectUri: "https://presto-qa.emerson.com/oidc/callback",
  ISSUER: "https://accessemr.okta.com",
  CLIENT_ID: "0oavjjmhd8BMImKTE1t7",
  appInsights: {
    connectionString: "InstrumentationKey=e7f0c2c3-2ea8-4023-bd61-21489106e5c6;IngestionEndpoint=https://eastus2-3.in.applicationinsights.azure.com/;LiveEndpoint=https://eastus2.livediagnostics.monitor.azure.com/;ApplicationId=e8173a2a-2391-4ff9-a330-64c5fa814a9f"
  }
}