import { DialogType } from "src/app/shared/enums/common";

export class ConfirmationModal {
    constructor() {
        this.isCheckbox = false;
        this.checkboxes = [];
        this.input = false;
        this.title = "";
        this.message = "";
        this.inputValue = '';
        this.buttons = [];
    }
    title: string;
    message: string;
    buttons: any[];
    isCheckbox: boolean;
    checkboxes: any[];
    input?: boolean;
    inputValue?: string; 
    data?: any;
    dialogType?: DialogType;
}