import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import { ApplicationInsights, DistributedTracingModes } from '@microsoft/applicationinsights-web';
import { SecurityService } from 'projects/presto-security/src/public_api';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AppInsightsService {
  instance?: ApplicationInsights;

  constructor(router: Router, securityService: SecurityService) {
    if (!environment?.appInsights?.connectionString) {
      console.warn("App insights not configured");
      return;
    }
    const angularPlugin = new AngularPlugin();
    this.instance = new ApplicationInsights({
      config: {
        connectionString: environment.appInsights.connectionString,
        enableCorsCorrelation: true,
        distributedTracingMode: DistributedTracingModes.AI_AND_W3C,
        extensions: [angularPlugin],
        extensionConfig: {
          [angularPlugin.identifier]: {
            router,
          },
        },
      },
    });
    this.instance.loadAppInsights();
    this.subscribeToAuthEvents(securityService);
    console.log('App insights is configured');
  }

  private subscribeToAuthEvents(securityService: SecurityService) {
    securityService.userSubject$.subscribe((user) => {
      if (user.isAuthenticated()) {
        this.instance?.setAuthenticatedUserContext(user.emailId);
        console.log('Set authenticated user: ', user.emailId);
      } else {
        this.instance?.clearAuthenticatedUserContext();
      }
    });
  }
}
