import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlertComponent } from "../../../../shared/components/alert/alert.component";
import { FormArray, FormBuilder, FormControl, FormGroup, FormsModule, Validators } from '@angular/forms';
import { DialogModule } from 'primeng/dialog';
import { ButtonComponent } from 'src/app/shared/components/button/button.component';
import { CommonModule } from '@angular/common';
import { SharedModule } from "../../../../shared/shared.module";
import { WorkspaceService } from 'src/app/shared/services/workspace/workspace.service';
import { IWorkspaceTreeViewData } from 'src/app/pages/workspace-treeview/model/project-tree-view.model';
import { TreeViewSummaryService } from 'src/app/pages/workspace-treeview/services/tree-view-summary.service';
import { ToastAlertService } from 'src/app/shared/services/toast-alert/toast-alert.service';

@Component({
  selector: 'app-create-tag',
  standalone: true,
  imports: [
    AlertComponent,
    FormsModule,
    DialogModule,
    ButtonComponent,
    CommonModule,
    SharedModule
],
  templateUrl: './create-tag.component.html',
  styleUrl: './create-tag.component.scss'
})
export class CreateTagComponent implements OnInit {
  @Input() isVisibleCreateTagModal: boolean;
  @Input() tableData: any;
  @Output() isVisibleCreateTagModalChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() quantityValues:{oldValue:number, newValue:number, currentValue:number} ;
  form:FormGroup;
  deleteIconVisible:boolean=false;
  newQty:number;
  oldQty:number;
  
  constructor(private fb:FormBuilder, 
    private _workspaceService:WorkspaceService,
  private _treeViewSummaryService: TreeViewSummaryService,
private toastAlertService: ToastAlertService) {

  }

  ngOnInit(): void {
    this.newQty=this.quantityValues.newValue;
    this.oldQty=this.quantityValues.oldValue;
    this.form = this.fb.group({
      rows: this.fb.array(
        this.tableData.map((item: any) => {
          return this.fb.group({
            id: [item.id], // Make sure this is wrapped in an array
            tag: [item.tag], // Make sure this is wrapped in an array
            name: [item.name, [Validators.minLength(1), Validators.maxLength(20), Validators.required]],
            isEditing:[item.isEditing]
          });
        })
      )
    });
    if(this.tableData.length > this.quantityValues.currentValue) {
      this.deleteIconVisible=true;
    } else {
      this.deleteIconVisible= false;
    }
  }
  

  get rows(): FormArray {
    return this.form.get('rows') as FormArray;
  }
  
  editingRowIndex: number | null = null;

  close_popup() {
    this.isVisibleCreateTagModal = false;
    this.isVisibleCreateTagModalChange.emit(this.isVisibleCreateTagModal);
  }

  clearInputs() {
    this.rows.controls.forEach((element,i) => {
      element.get('name')?.setValue('')
    });
  }

  editTag(index: number) {
    // this.editingRowIndex = index;
    // this.tableData.forEach((tag:any, i:any) => {
    //   tag.isEditing = i === index;
    // });
    this.rows.controls.forEach((element,i) => {
      element.get('isEditing')?.setValue(i=== index)
    });
  }

  saveTag(index: number, newValue: string) {
    this.tableData[index].tagName = newValue;
    this.tableData[index].isEditing = false;
    this.editingRowIndex = null;
  }

  isAnotherRowEditing(currentIndex: number): boolean {
    return (
      this.editingRowIndex !== null && this.editingRowIndex !== currentIndex
    );
  }

  deleteTag(index: number) {
    // this.tableData.splice(index, 1);
    // if (this.editingRowIndex === index) {
    //   this.editingRowIndex = null;
    // }
    this.rows.removeAt(index);
    if(this.quantityValues.currentValue >= this.rows.length) {
      this.deleteIconVisible= false;
    } else {
      this.deleteIconVisible= true;
    }
  }

  saveData() {
    let data:{'name':string,'id':number}[]=[];
    let saveData=this.form.value.rows;
    let proId = this._workspaceService.getworkspaceId();
    for(let i=0; i<saveData.length;i++) {
      data.push({
        "name": saveData[i].name,
        "id": saveData[i].id
      })
    }
    const payload = {
      "userId": "1",
      "itemId": this.form.value.rows[0].id,
      "newItemQuantity": this.newQty,
      "workspaceId": proId,
      "itemNames": data
  }
 
  if(this.form.valid) {
  this._treeViewSummaryService.updateQty(payload).subscribe((res: IWorkspaceTreeViewData) => {
    if (res.isSuccess) {
      this.toastAlertService.toastAlert(res.message,"success","Success","summary-view");
      this._workspaceService.getTreeViewSummaryData(res);
      this.isVisibleCreateTagModal=false;
    } else {
      this.toastAlertService.toastAlert(res.message,"error","Error","summary-view");
    }
  })
}
  }
}
