import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';
import { DialogModule } from 'primeng/dialog';
import { CommonModule } from '@angular/common';
import { CheckboxModule } from 'primeng/checkbox';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { ButtonComponent } from 'src/app/shared/components/button/button.component';
import { ModalComponent } from 'src/app/shared/components/modal/modal.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { DialogType } from 'src/app/shared/enums/common';
import { ConfirmationModal } from './model/confirmation-popup';
import { Construction } from 'src/app/pages/tag-workspace/store/models/build-assembly/construction';
import { Component as ConComponent } from 'src/app/pages/tag-workspace/store/models/build-assembly/component';
import { ConfirmationPopUp } from 'src/app/pages/tag-workspace/store/models/build-assembly/confirmationpopup';
class Attribute {
  primaryAttribute: string;
  secondaryAtribute: string;
  internalAttribute: string;
}
@Component({
  selector: 'app-confirmation-popup',
  standalone: true,
  imports: [
    DialogModule,
    ButtonComponent,
    ModalComponent,
    CommonModule,
    SharedModule,
    CheckboxModule,
    FormsModule,
    ButtonModule,
  ],
  templateUrl: './confirmation-popup.component.html',
  styleUrl: './confirmation-popup.component.scss',
})
export class ConfirmationPopUpComponent implements OnInit {
  isWarningModalOpen: boolean = false;
  @Input() modalData = new ConfirmationModal();
  @Input() isVisibleConfirmationPopUp: boolean = false;
  @Output() closeConfirmationPupUp = new EventEmitter();
  @Output() closeConflictPupUp = new EventEmitter();
  @Output() checkboxes = new EventEmitter();
  isButtonDisabled: boolean = true;
  activeTab: string = '';
  DialogType = DialogType;
  attributes: Attribute[] = [];
  confirmationPopup: ConfirmationPopUp;
  activeComponent: ConComponent; 
 
  ngOnInit(): void {
  
  }
  
  handleInputChange(event: any) {
    if (event) {
      this.isButtonDisabled = false;
      if(this.modalData?.dialogType === DialogType.ModelAfter){
        if (event && !event.startsWith('*')) {
          this.modalData.inputValue = '*' + event;
        } else if(event && event.startsWith('*') && event.length === 1){
          this.isButtonDisabled = true;
          this.modalData.inputValue = '';
        }
        this.modalData.data.inputValue = this.modalData.inputValue;
      }
    } else {
      this.isButtonDisabled = true;
    }
  }
  
  getSanitizedHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  closeConfirmationModal(value: string){
    this.isCheckboxSelected = false;
    if(this.modalData.checkboxes.length > 0){
      if(value === 'Add')
        this.checkboxes.emit(this.modalData.checkboxes);
      else
        this.checkboxes.emit([]);
    } else {
      this.closeConfirmationPupUp.emit(value);
    }
  }

  isCheckboxSelected = false;
  checkboxValueChanged(){
    this.isCheckboxSelected = false;
    let matchTO = this.modalData.checkboxes.find(f => f.isSelected === true);
    if(matchTO)
      this.isCheckboxSelected = true;
  }

  onClickConflictPopUpBtn(key: string){
    this.closeConflictPupUp.emit(key);
  }

  constructor(private sanitizer: DomSanitizer) {}
}
