import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, shareReplay, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CommonApiService {
  private cache: Map<string, Observable<any>> = new Map();
  constructor(private http: HttpClient) {}

  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      'content-type': 'application/json', 
      "version": "1"
    }),
  };
  deleteHttpOptions = {
    headers: new HttpHeaders({
      'content-type': 'application/json', 
      "version": "1"
    }),
    body: null
  };

  // GET request
  get(url: string, httpOption: any = undefined): Observable<any> {
    return this.http.get(url, httpOption ?? {}).pipe(catchError(this.handleError));
  }

  //get request for blob
  getBlob(url: string): Observable<Blob> {
    return this.http.get(url, { responseType: 'blob'}).pipe(catchError(this.handleError));
  }

  // GET request with headers
  getJSON<T>(url: string, httpOption: any = undefined): Observable<T> {
    let httpOptions = { ...this.httpOptions };
    if(httpOption)
      httpOptions = httpOption;
    return this.http.get<T>(url, httpOptions).pipe(catchError(this.handleError));
  }

  getStaticData<T>(url: string): Observable<T> {
    if(!this.cache.has(url)){
      const data$ = this.http.get(url).pipe(
        catchError(this.handleError),
        shareReplay()
      );
      this.cache.set(url, data$);
    }
    return this.cache.get(url)!;
  }

  // POST request
  post<T>(url: string, data: any, httpOption: any = undefined): Observable<T> {
    let httpOptions = { ...this.httpOptions };
    if(httpOption)
      httpOptions = httpOption;
    return this.http.post<T>(url, data, httpOptions).pipe(catchError(this.handleError));
  }

  // PUT request
  put<T>(url: string, data: any): Observable<T> {
    return this.http.put<T>(url, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  // DELETE request
  delete(url: string, body: any): Observable<any> {
    const httpOptions = { ...this.deleteHttpOptions };
    httpOptions.body = body;
    return this.http.delete(url, httpOptions).pipe(catchError(this.handleError));
  }

  // Example method for handling errors
  private handleError(error: HttpErrorResponse): Observable<never> {
    let errorMessage = 'Unknown error occurred';
    if (error.error instanceof ErrorEvent) {
      // Client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.error(errorMessage);
    return throwError(() => errorMessage);
  }
}
