export class FlexSerialCard {
    serialNumber: string[] | string;
    pdfFileName: string;
    internal: boolean;
    preview: boolean;
    serialReportCardType:number;
    documentType:number;
    constructor() {
        this.serialNumber = "";
        this.pdfFileName= "abc";
        this.internal = true;
        this.preview = true;
    }
}