import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { SharedModule } from "../../../shared/shared.module";
import { CellClickedEvent, CellValueChangedEvent, ColDef, GetContextMenuItemsParams, GridApi, GridOptions, GridReadyEvent, ITextFilterParams, MenuItemDef, ValueSetterParams } from 'ag-grid-community';
import { AgGridAngular } from 'ag-grid-angular';
import { WorkspaceService } from 'src/app/shared/services/workspace/workspace.service';
import { WorkspaceTreeViewLibraryService } from '../../workspace-treeview/workspace-treeview-library.service';
import { TagWorkSpaceService } from '../../tag-workspace/store/services/state/tag-workspace/tagworkspace.service';
import { Router } from '@angular/router';
import { IGroup, IItem, IItemNames, Iqtylevel, IRenameConstructionLevel, ISummaryView, ITreeView, IupdatehashTag, IUpdateItemNumber, IWorkspaceTreeViewData } from '../../workspace-treeview/model/project-tree-view.model';
import { TreeViewSummaryService } from '../../workspace-treeview/services/tree-view-summary.service';
import { ConfirmationPopUpComponent } from 'src/app/shared/components/confirmation-popup/confirmation-popup.component';
import { ConfirmationModal } from 'src/app/shared/components/confirmation-popup/model/confirmation-popup';
import { ConfirmationPopUpService } from 'src/app/shared/components/confirmation-popup/service/confirmation-popup.service';
import { DialogType } from 'src/app/shared/enums/common'
import { ToastModule } from 'primeng/toast';
import { ToastAlertService } from 'src/app/shared/services/toast-alert/toast-alert.service';
import { Breadcrumb } from 'src/app/shared/models/breadcrumb';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { getTreeData } from 'src/app/store/treedata.actions';
import { AgGridService } from 'src/app/shared/services/mro/ag-grid.service';
import { CreateTagComponent } from '../popups/create-tag/create-tag.component';

@Component({
  selector: 'app-summary-view',
  standalone: true,
  imports: [
    SharedModule,
    AgGridAngular,
    ConfirmationPopUpComponent,
    ToastModule,
    CreateTagComponent
],
  templateUrl: './summary-view.component.html',
  styleUrl: './summary-view.component.scss'
})
export class SummaryViewComponent implements OnInit, OnDestroy {
  gridOptions: GridOptions;
  columnDefs: ColDef[];
  rowData: any;
  gridApi!: GridApi;
  groupsTab: IGroup[] = [];
  totalSelectedItemCount: number = 0;
  confirmationPopUpData = new ConfirmationModal();
  isVisibleConfirmationModal = false;
  noRowsTemplate = `
  <span style="display: flex; gap: 10px; ">
    <img class="row-icon" src="../../../../../assets/detail-icon.svg"> No Rows to Show
  </span>
`;
  treeViewData: IWorkspaceTreeViewData;
  summaryChecked = true;
  multitagViewChecked = false;
  updateSummary$:Subscription;
  allSubscriptions: Subscription[] = [];
  getProjectSummarySubscribition$: Subscription;
  workspaceId: number;
  summaryData: ITreeView;
  groupNameList:string[] = [];
  groups: { groupId: number; groupName: string; }[];
  isVisibleCreateTagModal: boolean;
  summaryRowData:ISummaryView[]=[];
  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
  }
  reloadSummaryDataSub$: Subscription;
  currencyCode: string = "";
  public getContextMenuItems: (params: GetContextMenuItemsParams) => (string | MenuItemDef)[] = this._agGridService.getContextMenuItems;
  constructor(
    private readonly _workspaceService: WorkspaceService,
    private readonly treeViewSummaryLib: WorkspaceTreeViewLibraryService,
    private readonly tagworkspaceService: TagWorkSpaceService,
    private readonly router: Router,
    private readonly _treeViewSummaryService: TreeViewSummaryService,
    private readonly confirmationPopUpService:ConfirmationPopUpService,
    private readonly cdRef: ChangeDetectorRef,
    private readonly toastAlertService: ToastAlertService,
    private readonly store: Store,
    private readonly _agGridService: AgGridService
  ) {
    this.currencyCode = this._workspaceService.getCurrencyCode();
    this.setColumnDefinations();
    this.gridOptions = <GridOptions>{
      domLayout: 'autoHeight', // Set the domLayout property to 'autoHeight',
    };
    this.rowData = [];
  }
    // DefaultColDef sets props common to all Columns
    public defaultColDef: ColDef = {
      suppressSizeToFit: true,
      menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
      filterParams: {
        buttons: ['apply', 'cancel'],
        closeOnApply: true,
        excelMode: 'windows',
      } as ITextFilterParams,
      editable: false,
      sortable: true,
      filter: true,
      resizable: true,
      sortingOrder: ["asc", "desc"],
      comparator: (valueA, valueB, nodeA, nodeB, isDescending) => valueA - valueB,
    };

  tableData: { id: number; tag: string; name:string, isEditing:boolean }[]= []
  quantityData: {newValue:number, oldValue:number, currentValue:number}={
    newValue: 0,
    oldValue: 0,
    currentValue: 0
  }

  private populateTableData(rowData: any, value: number): void {
    this.tableData = [];
    console.log(value,'val');
    console.log(rowData);
    console.log(rowData.itemNamesArr,'itarr');
    for (let i = 0; i <= value; i++) {
      if (!rowData.itemNamesArr.length) {
        this.tableData.push({
          id: rowData.itemId,
          tag: `Tag ${i + 1}`,
          name: i === 0 ? rowData.itemName : '',
          isEditing: false,
        });
      } else {
        if(rowData.itemNamesArr[i]?.name !== undefined) {
        this.tableData.push({
          id: rowData.itemId,
          tag: `Tag ${i + 1}`,
          name: rowData.itemNamesArr[i]?.name || '',
          isEditing: false,
        });
      }
      }
    }
    this.cdRef.detectChanges();
  }

  isVisibleCreateTagModalChange(value: boolean) {
    this.isVisibleCreateTagModal = value;
  }

  valueSetterQty(params: ValueSetterParams) {
    const newValInt = isNaN(params.newValue); //0
    const valueChanged = params.data.itemQuantity !== newValInt;
    if (valueChanged && params.newValue <= 32767 && params.newValue != 0) {
      params.data.itemQuantity = (valueChanged && params.newValue <= 32767) ? params.newValue : params.oldValue;

      let proId = this._workspaceService.getworkspaceId();
      let itemArr = params.data.itemNamesArr;
      
      const itemNames:IItemNames[] = []
      let itemArrLength = itemArr?.length;
      if(itemArrLength === 0){
        let itemObj:{ id:number, name:string} ={
          id: 0,
          name: ''
        };
        itemObj["id"]=params.data.itemId;
        itemObj["name"]=params.data.itemName;
        itemNames.push(itemObj)
      }
      for(let i=1; i<=itemArrLength; i++){
          let itemObj:{ id:number, name:string} ={
            id: 0,
            name: ''
          };
          itemObj["id"]=params.data.itemId;
          itemObj["name"]=itemArr[i-1].name;
          itemNames.push(itemObj)
        }
        if(params.data.itemQuantity>itemArrLength){
          var emptyObj=itemArrLength === 0?(params.data.itemQuantity-itemArrLength)-1:(params.data.itemQuantity-itemArrLength)
          for(let i=1; i<=emptyObj; i++){
            let itemObj:{ id:number, name:string} ={
              id: 0,
              name: ''
            };
            itemObj["id"]=params.data.itemId;
            itemObj["name"]="";
            itemNames.push(itemObj)
          }
        }
       
  params.data.itemNamesArr = itemNames;
 
      let modelData: Iqtylevel = {
        userId: "1",
        itemId: params.data.itemId,
        newItemQuantity: params.data.itemQuantity,
        workspaceId: proId,
        itemNames:itemNames
      }
      this.quantityData={newValue: params.newValue,
        oldValue: params.oldValue, currentValue: params.data.itemQuantity}
      this.updateQty(modelData,params)
    } else {
      this.confirmingPopup("Warning",true,"Quantity should be greater than 0 and less than 32,767",DialogType.SimpleDilaog);
    }
    return valueChanged;
  }

  updateQty(modeldata: Iqtylevel,params:ValueSetterParams) {
    this._treeViewSummaryService.updateQty(modeldata).subscribe((res: IWorkspaceTreeViewData) => {
      if (res.isSuccess) {
        this.toastAlertService.toastAlert(res.message,"success","Success","summary-view");
        this._workspaceService.getTreeViewSummaryData(res);
        
        this.populateTableData(this.summaryRowData,params.data.itemNamesArr.length);
        this.isVisibleCreateTagModal=true;
      } else {
        this.toastAlertService.toastAlert(res.message,"error","Error","summary-view");
      }
    })
  }

  handleQtyClick(event: any): void {
    console.log("event", event);
    this.summaryRowData=[];
    const rowData = event.data;
    console.log(rowData,'rows');
    this.summaryRowData=event.data;
    this.quantityData={newValue: event.data.itemQuantity,
    oldValue: event.data.itemQuantity, currentValue:event.data.itemQuantity}
    const target = event.event.target;
    if (target.classList.contains('qty-icon')) {
      const value = rowData.itemNamesArr.length;
      this.quantityData.newValue=value;
      console.log("value", rowData.itemNamesArr.length);
      if (value > 1) {
        this.isVisibleCreateTagModal = true;
        this.populateTableData(rowData, value);
        if (this.gridApi) {
          this.gridApi.stopEditing(); // Stops editing if the grid API is ready
        }
      } else {
        this.isVisibleCreateTagModal = false;
      }
    }
  }

  ngOnInit(): void {
    this.setBreadcrumbs();
    this.workspaceId = this._workspaceService.getworkspaceId();
    this.getTreeViewSummary(this.workspaceId, 1);
    this.updateSummaryView();
  }
  onCellValueChanged(event: CellValueChangedEvent) {
    //find the groupId based on the groupName
    const groupId = this.groups.find((group) => group.groupName === event.newValue)?.groupId;
    if(event.colDef.field === 'groupName'){
      const modeldata = {
        destinationGroupId: groupId,
        sourceGroupId: event.data.groupId,
        itemId: event.data.itemId,
        userId: '1',
        workspaceId: this._workspaceService.getworkspaceId(),
      }
      this.updateItemGroup(modeldata);
    }
  }
  //api call to move the item to one group to another group
  updateItemGroup(modelData: any) {
    this._treeViewSummaryService.updateItemGroup(modelData).subscribe({
      next: (response: IWorkspaceTreeViewData) => {
        if (response.isSuccess) {
          this._workspaceService.getTreeViewSummaryData(response);
          this.toastAlertService.toastAlert(response.message,"success","Success","summary-view");
        } else {
          this.toastAlertService.toastAlert(response.message,"error","Error","summary-view");
        }
      },
      error: (err) => {
        this.toastAlertService.toastAlert(err.error.ErrorMessage,"error","Error","summary-view");
      }
    });
  }
  updateSummaryView() {
    this.updateSummary$ = this._workspaceService.updateTreeViewSummary$.subscribe((treeViewData) => {
      this.totalSelectedItemCount = 0;
      // Check if object should not be empty
      if (Object.keys(treeViewData).length > 0) {
        this.treeViewData = treeViewData;
        this.groupsTab = treeViewData.treeView.groups;
        this.groupNameList = treeViewData.treeView.groups.map((group: IGroup) => group.groupName); 
        const groupColumn = this.gridApi.getColumnDef('groupName');
        //return group name and id 
        this.groups = this.treeViewData.treeView.groups.map((group: IGroup) => {
          return {
            groupId: group.groupId,
            groupName: group.groupName,
          };
        });

        if (groupColumn?.cellEditorParams) {
          groupColumn.cellEditorParams.values = this.groupNameList;
        }
        // under groupTab all the items are length need to get the length of the items
        // check items checked true or false
        this.groupsTab = this.groupsTab.filter((group: IGroup) => {
          group.items = group.items.filter((item: IItem) => item.checked === true);
          this.totalSelectedItemCount += group.items.length;
          return group.items.length > 0;
        });
        const summaryViewData = this.treeViewSummaryLib.getSummaryViewList(treeViewData.treeView);
        this.rowData = [...summaryViewData];
      }
    });
    this.reloadSummaryDataSub$ = this._workspaceService.reloadSummaryData$.subscribe(() => {
      this.currencyCode = this._workspaceService.getCurrencyCode();
      this.setColumnDefinations();
      this.getTreeViewSummary(this.workspaceId, 1);
    });
    this.allSubscriptions.push(this.updateSummary$);
    this.allSubscriptions.push(this.reloadSummaryDataSub$);
  }
  //api call to get the tree view summary data
  getTreeViewSummary(workspaceId: number, userId: number) {
    this.getProjectSummarySubscribition$ = this._workspaceService.getProjectSummary(workspaceId, userId).subscribe({
      next: (res) => {
        res.treeView.isApiCall = true;
        this._workspaceService.getTreeViewSummaryData(res);
        this.summaryData = res.treeView;
        this.dispatchToStore();
      },
    });
    this.allSubscriptions.push(this.getProjectSummarySubscribition$);
  }
  dispatchToStore() {
    const treeData = JSON.parse(JSON.stringify(this.summaryData));
    this.store.dispatch(getTreeData({ treeViewData: treeData }));
  }
  setBreadcrumbs(){
    let workSpaceName = this._workspaceService.getworkspaceName();
    let breadcrumbs: Breadcrumb[] = [
      {
        title: workSpaceName,
        route: 'tree-view/summary-view',
        rev: '',
        project: workSpaceName.toUpperCase(),
        active: false,
        displayProjectTitle: true
      },
    ];
    this._workspaceService.setBreadcrumbs(breadcrumbs);
  }


  onSelectChange(selectedValue: string) {
    this.selectedOption = selectedValue;
    if (this.selectedOption !== 'Select View mode') {
      this.multitagViewChecked = true;
      this.summaryChecked = false;
    }
  }

  selectOptions: { label: string; value: string }[] = [
    {
      label: 'Select View mode',
      value: 'Select View mode',
    },
    {
      label: 'ISA Format',
      value: 'ISA Format',
    },
    {
      label: 'Fisher Format',
      value: 'Fisher Format',
    },
    {
      label: 'Product Attributes',
      value: 'Product Attributes',
    },
    {
      label: 'Engineering Module (EM) Data',
      value: 'Engineering Module (EM) Data',
    },
  ];
  selectedOption: string = 'Select View mode';
  valueSetterItem(params: ValueSetterParams) {
    const isAlphaNumeric = (str: string) => /^[a-z0-9]+$/gi.test(str) && /\d/.test(str);
    const valueChanged = isAlphaNumeric(params.newValue);
    if (valueChanged && params.newValue.length <= 6 && params.newValue != 0) {
      params.data.item = valueChanged ? params.newValue : params.oldValue;
     
      let workspaceId = this._workspaceService.getworkspaceId();
      let modelData: IUpdateItemNumber = {
        userId: "1",
        itemId: params.data.itemId,
        itemNumber: params.data.item,
        workspaceId: workspaceId,
      }
      this.updateItemNumber(modelData);
    } else {
      this.confirmingPopup("Warning",true,"Item number cannot be more than 6 characters long",DialogType.SimpleDilaog);    
    }
    return params.data.item;
  }
  updateItemNumber(modeldata: IUpdateItemNumber){
    this._treeViewSummaryService.updateItemNumber(modeldata).subscribe((res: IWorkspaceTreeViewData) => {
      if(res.isSuccess){
        this.toastAlertService.toastAlert(res.message,"success","Success",'summary-view');
        this._workspaceService.getTreeViewSummaryData(res);
      }else{
        this.toastAlertService.toastAlert(res.message,"error","Error",'summary-view');
      }
    })
  }
  valueSetterHashItem(params: ValueSetterParams) {
    const valueChanged = (params.newValue);
    if (valueChanged && params.newValue.length <= 100 && params.newValue != 0) {
      params.data.hashTags = params.newValue.length <= 100 ? params.newValue : params.oldValue;
      let modelData: IupdatehashTag = {
        userId: "1",
        itemId: params.data.itemId,
        hashItem: params.data.hashTags,
        workspaceId: this._workspaceService.getworkspaceId()
      }
      this.updateHashtag(modelData);

    } else {
      this.confirmingPopup("Warning",true,"Hashtag cannot be more than 100 characters long",DialogType.SimpleDilaog);    
    }
    return params.data.hashTags;
  }
  updateHashtag(modeldata: IupdatehashTag) {
    this._treeViewSummaryService.updateHashtag(modeldata).subscribe({
      next: (response:IWorkspaceTreeViewData) => {
        if (response.isSuccess) {
          this._workspaceService.getTreeViewSummaryData(response);
          this.toastAlertService.toastAlert(response.message,"success","Success","summary-view");
        } else {
          this.toastAlertService.toastAlert(response.message,"error","Error","summary-view");
        }
      },
      error: (err) => {
        this.toastAlertService.toastAlert(err.error.ErrorMessage,"error","Error","summary-view");
      }
    });
    
  }
  valueSetterItemDesc(params: ValueSetterParams) {
    if(!params.newValue){
      params.newValue = "";
    }
    const valueChanged =(params.newValue);
    if (params.newValue.length <= 255) {
      let workspaceId = this._workspaceService.getworkspaceId()
      let modelData: IRenameConstructionLevel = {
        userId: "1",
        constructionId: params.data.constructionId,
        newConstructionDescription: params.newValue,
        workspaceId: workspaceId,
        itemId:params?.data?.itemId
      }
      
      this.renameConstruction(modelData)
    } else {
     
      this.confirmingPopup("Warning",true,"Construction Description cannot be more than 255 characters long",DialogType.SimpleDilaog);
    }
    return valueChanged;
  }
  renameConstruction(payloadData: any) {
    if (payloadData) {
      this._treeViewSummaryService.renameConstruction(payloadData).subscribe({
        next: (response:IWorkspaceTreeViewData) => {
          if (response.isSuccess) {
            this._workspaceService.getTreeViewSummaryData(response);
            this.toastAlertService.toastAlert(response.message,"success","Success","summary-view");
          } else {
            this.toastAlertService.toastAlert(response.message,"error","Error","summary-view");
          }
        },
        error: (err) => {
          this.toastAlertService.toastAlert(err.error.ErrorMessage,"error","Error","summary-view");
        }
      });
    } else {
      this.confirmingPopup("Warning",true,"Construction Description cannot be more than 255 characters long",DialogType.SimpleDilaog);
    }
  }
  confirmingPopup(title:string,isModel:boolean,message:string,dialogType:number){
    this.isVisibleConfirmationModal = isModel;
    this.confirmationPopUpData.title = title;
    this.confirmationPopUpData.message = message;
    this.confirmationPopUpData.buttons = this.confirmationPopUpService.getButtonsByDialogType(dialogType);
    this.cdRef.detectChanges();
  }
  closeConfirmationPupUp(value: string) {
    this.isVisibleConfirmationModal = false;
    this.cdRef.detectChanges();
  }
  removeGroup(group: IGroup) {
    // this.groupsTab = this.groupsTab.filter((grp: IGroup) => grp.groupId !== group.groupId);
    //selected group and items rev and constructionsshould be checked false
  //   this.treeViewData.treeView.groups = this.treeViewData.treeView.groups.map((grp: IGroup) => {
  //     if (grp.groupId === group.groupId) {
  //       grp.checked = false;
  //       grp.items = grp.items.map((item: IItem) => {
  //         item.checked = false;
  //         return item;
  //       });
  //     }
  //     return grp;
  //   });
  //   this._workspaceService.getTreeViewSummaryData(this.treeViewData);
  }

  setColumnDefinations(){
    this.columnDefs = [
      {
        headerName: 'Line #',
        field: 'item',
        width: 100,
        editable: true,
        valueSetter: (params: ValueSetterParams) => { return this.valueSetterItem(params) },
        pinned: 'left'
      },
      {
        headerName: 'Tag',
        field: 'itemName',
        width: 140,
        cellClass: '!text-green-light',
        pinned: 'left',
        cellStyle: (params: any) => {
          if (params.data && params.data.itemType === "Configured Item") {
            return { cursor: 'pointer', textDecoration: 'underline' };
          }
          return null;
        },
        onCellClicked: (event: CellClickedEvent) => {
          if(event.data.itemType == "Configured Item"){
            this.tagworkspaceService.tagDetails = event.data;
            this.router.navigate(['item-workspace'])
          }
        }
      },
      {
        headerName: 'Item  Type',
        field: 'itemType',
        width: 140,
      },
      {
        headerName: 'Rev',
        field: 'revisionName',
        width: 100,
      },
      {
        headerName: 'Group',
        field: 'groupName',
        editable: true,
        cellEditor: 'agSelectCellEditor', // Use AG Grid's built-in select cell editor
        cellEditorParams:()=> {
          return {
            values: this.groupNameList,
          }
        },
        cellRenderer: (params: any) => {
          return `<div class='flex'>
          <div>${params.value}</div>
          <div class='flex ml-[6.7rem] mt-[1rem]'><i class='fas fa-sm fa-caret-down'></i></div></div>`;
        },
      },
      {
        headerName: 'Construction Type',
        field: 'constructionType',
      },
      {
        headerName: 'Quantity',
        field: 'itemQuantity',
        width: 125,
        editable: true,
        cellRenderer: (params: any) => {
          const isClickable = params.value > 1;
          return `
          <div class='flex'>
            <div>${params.value}</div>
            <div class='flex'>
              <img
                src='../../../../assets/icons/qty_tag.svg'
                style="cursor: ${
                  isClickable ? 'pointer' : 'not-allowed'
                }; opacity: ${isClickable ? 1 : 0.5}"
                class="qty-icon"
                data-row-index="${params.rowIndex}"
                data-value="${params.value}"
              />
            </div>
          </div>
        `;
        },
        valueSetter: (params: ValueSetterParams) => { return this.valueSetterQty(params) },
        onCellClicked: (event: CellClickedEvent) => {
          this.handleQtyClick(event);
        },
      },
      {
        headerName: 'Hashtags',
        field: 'hashItems',
        editable: true,
        width: 140,
        valueSetter: (params: ValueSetterParams) => { return this.valueSetterHashItem(params) },
      },
      {
        field:'serviceDescription',
        headerName: "Service",
        width: 140,
      },
      {
        headerName: 'Description',
        field: 'longDescription',
        valueSetter: (params: ValueSetterParams) => { return this.valueSetterItemDesc(params) },
        editable: true,
        width:180
      },
      {
        field: 'conflicts', 
        headerName: 'Conflicts',
        width: 160,
      },
      {
        headerName: 'Line Class',
        field: 'lineClass',
        width: 200,
      },
      {
        headerName: 'Unit Price('+ this.currencyCode +')',
        field: 'unitPrice',
        width: 200,
        cellStyle: () => {
          return { textAlign: 'end' };
        },
      },
      {
        headerName: 'Price Factor',
        field: 'priceFactor',
        width: 220,
      },
      {
        headerName: 'Consumer Net Price',
        field: 'consumerNetPrice',
        width: 260,
        cellStyle: () => {
          return { textAlign: 'end' };
        }
      },
      {
        headerName: 'Ext. Disc. %',
        field: 'extendedDiscount',
        width: 200,
      },
      {
        headerName: 'Customer Gross Price',
        field: 'customerGrossPrice',
        width: 220,
        cellStyle: () => {
          return { textAlign: 'end' };
        }
      },
      {
        headerName: 'Unit List('+ this.currencyCode +')',
        field: 'unitList',
        cellStyle:()=>{
          return {textAlign:'end'}
        }
      },
      {
        field: 'totalPrice',
        headerName: 'Total Price('+ this.currencyCode +')',
        cellStyle: (params) => {
          if (params.node?.data.isComplete === true) {
            return { color: '#000', textAlign: 'end' };
          } else {
            return { color: '#B3261E', textAlign: 'end' };
          }
        },
      },
      {
        headerName: 'Transfer Co-efficient',
        field: 'transferCoEfficient',
        width: 250,
      },
      {
        headerName: 'Gross Margin %',
        field: 'grossMarginPercent',
      },
      {
        headerName: 'Effective Price Date',
        field: 'effPriceDate',
      },
      {
        headerName: 'FAST',
        field: 'fAST',
  
      },
      {
        headerName: 'Prime Ship',
        field: 'primeShip',
        width: 250,
      },
      {
        headerName: 'Lead Time',
        field: 'leadTime',
        width: 150,
      },
      {
        headerName: 'Lead Time Zone Info',
        field: 'leadTimeZoneInfo',
      },
      {
        headerName: 'Application Code',
        field: 'applicationCode',
      },
      {
        headerName: 'Serial#',
        field: 'serialNumber',
        width: 150,
      },
    
      {
        headerName: 'Created Date',
        field: 'itemCreatedDate',
        width: 150,
      },
      {
        headerName: 'Revision Date',
        field: 'revisionDate',
        width: 230,
      },
      {
        headerName: 'Last Update Date',
        field: 'lastUpdatedDate',
        width: 200,
      },
      {
        headerName: 'Created By',
        field: 'createdBy',
        width: 230,
      },
      {
        headerName: 'Last Updated By',
        field: 'lastUpdatedBy',
        width: 230,
      },
      {
        headerName: 'ATP Request Date',
        field: 'aTPRequestDate',
        width: 230,
      },
      {
        headerName: 'Available On',
        field: 'aTPAvailableOn',
        width: 200,
      },
      {
        headerName: 'Available Until',
        field: 'aTPAvailableUntil',
        width: 200,
      }
    ];
  }

  ngOnDestroy() {
    this.allSubscriptions.forEach((sub:Subscription) => sub.unsubscribe());
  }
}
