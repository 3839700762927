import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class ToastAlertService {

  constructor(private readonly messageService: MessageService) { }

  toastAlert(msgDesc:string,toastType:string,header:string,key:string) {
    this.messageService.clear();
    this.messageService.add({ key: key, 
        severity: toastType, 
        summary: header, 
        detail: msgDesc,
      });
  }
}
