import { Construction, ErrorInfo } from "./buildassembly";

export class AddProductAction {
    continue: boolean;
    cancel: boolean;
    hasConflict: boolean;
    turnOffPT: boolean;
    resolvePTManually: boolean;
    construction: Construction;
    error: ErrorInfo;

    constructor() {
        this.continue = true;
        this.cancel = false;
        this.hasConflict = false;
        this.turnOffPT = false;
        this.resolvePTManually = false;
        this.construction = new Construction();
        this.error = new ErrorInfo();
    }
}