import { Assignment, ConflictVariable } from 'src/app/pages/tag-workspace/store/models/build-assembly/buildassembly';

class Component {
    productLineId: number;
    productLineName: string;
    guid: string;
    primaryVariables: ConflictVariable[];
    secondaryVariables: ConflictVariable[];
    internalVariables: ConflictVariable[];
    completeConflictAttribuetNames: string[];

    constructor() {
        this.primaryVariables = [];
        this.secondaryVariables = [];
        this.internalVariables = [];
    }
}

export class ConflictUI {
    components: Component[];

    constructor() {
        this.components = [];
    }

    AddComponent(productLineId: number, productLineName: string, guid: string, primaryVariables: ConflictVariable[], secondaryVariables: ConflictVariable[], internalVariables: ConflictVariable[]) {
        let component: Component = new Component();
        component.completeConflictAttribuetNames = [];

        component.productLineId = productLineId;
        component.productLineName = productLineName;
        component.guid = guid;
        component.primaryVariables = primaryVariables;
        component.secondaryVariables = secondaryVariables;
        component.internalVariables = internalVariables;

        this.components.push(component);

        component.completeConflictAttribuetNames = [...this.loadCompleteAttributeIds(component.primaryVariables), ...this.loadCompleteAttributeIds(component.secondaryVariables), ...this.loadCompleteAttributeIds(component.internalVariables)];
    }

    loadCompleteAttributeIds(variables: ConflictVariable[]): string[] {
        let varNames: string[] = [];
        variables.forEach((conflictVariable: ConflictVariable) => {
            varNames.push(conflictVariable.variable);
        })

        return varNames;
    }
}