import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, Subscription, map } from 'rxjs';
import { CommonApiService } from 'src/app/shared/services/common-api.service';
import { Config } from '../../config';
import { ConstructionPayload } from '../../models/mro/construction-payload';
import { HeaderInfo } from '../../models/mro/header-info';
import { SerialCardRequest } from '../../models/mro/serial-card-request';
import { SerialCardDeconstruction } from '../../models/mro/serial-card-deconstruction';
import { CommonUtilityLibraryService } from '../../library/common-utility-library.service';
import { SearchResultSerialCard } from '../../models/mro/serial-cards-search';
import { RecentSearch } from '../../models/mro/recent-search';
import { BulkSerialSearchResult } from '../../models/mro/bulk-serial';
import { MfgLoction } from '../../models/mro/mfg-loction';
import { Office } from '../../models/mro/office';
import { Document, DocumentResponse, DocumentURL } from '../../models/mro/document';
import { FlexSerialCard } from '../../models/mro/flex-serial-card';
import { AppType } from '../../enums/mro/app-type';
import { FieldChangeRequest, FieldChange } from '../../models/mro/field-changes';
import { AddPartsInfo } from '../../models/mro/parts';
import { error } from '../../models/mro/error-msg';
import { PackingListPayload } from '../../models/mro/packinglist-payload';
import { CompareAPIPayload, CompareSerialPayload } from '../../models/mro/compare-serial-payload';
import { ProductsEmstringResponse } from '../../models/mro/products-emstring-response';
import { SerialSearchGQL, SerialSearchQuery } from './mro-api-serial.service.generated';
import { ItemTypes } from '../../enums/mro/mro-serial-card';

@Injectable({
  providedIn: 'root'
})
export class MroApiSerialService {

  private readonly config: Config;

  // BehaviorSubject to hold the response of the serial search
  public serialSearchResponse = new BehaviorSubject<HeaderInfo>({} as HeaderInfo);

  // Observable to expose the serialSearchResponse BehaviorSubject
  public serialSearchResponse$ = this.serialSearchResponse.asObservable();

  browserBackSubcription$: Subscription;

  public serialCardResults = new BehaviorSubject<SearchResultSerialCard[]>([]);
  public serialCardResults$ = this.serialCardResults.asObservable();

  public bulkSerialSearchResult = new BehaviorSubject<BulkSerialSearchResult>(new BulkSerialSearchResult());
  public bulkSerialSearchResult$ = this.bulkSerialSearchResult.asObservable();

  public recentSearchList = new BehaviorSubject<RecentSearch[]>([]);
  public recentSearchList$ = this.recentSearchList.asObservable();

  private readonly totalCount = new Subject<number>();
  totalCount$ = this.totalCount.asObservable();

  constructor(
    private readonly apiService: CommonApiService,
    private readonly culibrary: CommonUtilityLibraryService,
    private readonly serialSearchGQL: SerialSearchGQL,
  ) {
    this.config = new Config();
  }

  updateTotalCount(count: number) {
    this.totalCount.next(count);
  }

  private parseDate(dateString: SearchResultSerialCard['shipDate']): Date | null {
    if (!dateString) {
      return null;
    }
    return new Date(Date.parse(dateString));
  }

  private restResponseToFragments(serialCard: SearchResultSerialCard): SearchResultSerialCard {
    return {
      ...serialCard,
      __typename: 'SerialCard',
      serialNumber: serialCard.serialNumber,
      tagNumber: serialCard.tagNumber,
      orderLine: {
        __typename: 'OrderLine',
        oracleLineNumber: serialCard.oracleOrderLineNumber,
        customerLineNumber: serialCard.custLineNumber,
        repLineNumber: serialCard.repLineNumber,
        order: {
          __typename: 'Order',
          oracleOrderNumber: serialCard.oracleOrderNumber,
          customerOrderNumber: serialCard.customerPO,
          repOrderNumber: serialCard.repOrderNumber
        }
      },
      manufacturingLocation: {
        orgCode: serialCard.primeShip
      },
      shipDateAsDate: this.parseDate(serialCard.shipDate),
      customerProject: {
        projectNumber: serialCard.projectNumber,
        name: serialCard.manufacturer
      },
      actuatorComponent: serialCard.actuator ? {
        __typename: 'Component',
        name: serialCard.actuator,
      } : null,
      valve: serialCard.type ? {
        __typename: 'Component',
        name: serialCard.type,
        products: [
          {
            valveSize: {
              value: serialCard.size
            }
          }
        ]
      } : null,
      instrumentsComponent: serialCard.instruments ? {
        __typename: 'Component',
        name: serialCard.instruments,
      } : null
    }
  };

  // This function sends a POST request to the GET_SERIAL_CARD_API endpoint with the provided request object
  public getSearchedInformation(request: SerialCardRequest): Observable<HeaderInfo> {
    // FOr now, we need to convert results back and forth so that changes do not need to be made across the entire app.
    console.log("Executing search", request);
    const searchValues = request.searchText?.split(',').map(s => s.trim()) ?? [];
    const searchResultsToHeader = ({serialCards, serialCardCount}: SerialSearchQuery): HeaderInfo => {
      const errors: error[] = [];
      if (serialCardCount === 0) {
        errors.push({
          code: "204",
          message: "We couldn't find any results for your search. Try searching again with different keyword(s) / filters."
        });
      }
      return {
        totalRecordCount: serialCardCount,
        errors,
        warning: [],
        info: [],
        serialCards: serialCards.map(sc => ({
          ...sc,
          oracleOrderLineNumber: sc.orderLine.oracleLineNumber,
          oracleOrderNumber: sc.orderLine.order.oracleOrderNumber,
        } satisfies SearchResultSerialCard))
      };
    }

    switch (request.searchField?.toString()) {
      case ItemTypes.EMString:
        if (searchValues.length === 1) {
          return this.serialSearchGQL
            .fetch({ searchCriteria: { emStringPattern: searchValues[0] } })
            .pipe(map((results) => searchResultsToHeader(results.data)));
        }
        return this.serialSearchGQL
          .fetch({ searchCriteria: { emStrings: searchValues } })
          .pipe(map((results) => searchResultsToHeader(results.data)));
      case ItemTypes.Product:
        return this.serialSearchGQL
          .fetch({ searchCriteria: { productNumbers: searchValues } })
          .pipe(map((results) => searchResultsToHeader(results.data)));
      default:
        return this.apiService
          .post<HeaderInfo>(this.config.GET_SERIAL_CARD_API, request)
          .pipe(
            map(
              (headerInfo) =>
                ({
                  ...headerInfo,
                  serialCards: headerInfo.serialCards?.map((sc) =>
                    this.restResponseToFragments(sc)
                  ),
                } satisfies HeaderInfo)
            )
          );
    }
  }

  // This function sends a POST request to the GET_CONSTRUCTION_DETAILS_API endpoint with the provided request object
  public getConstructionDetails(request: ConstructionPayload): Observable<SerialCardDeconstruction> {
    return this.apiService.post(this.config.GET_CONSTRUCTION_DETAILS_API, request);
  }

  // public getBuildAssembly(){
  //   return this.apiService.get("assets/assembly/build-assembly.json");
  // }

  public getSerialSearchResponse(data: HeaderInfo) {
    this.serialSearchResponse.next(data);
  }

  public getSerialResults(serialCards: SearchResultSerialCard[]) {
    return this.serialCardResults.next(serialCards)
  }

  public getBulkSerialResult(bulkSerialSearch: BulkSerialSearchResult) {
    return this.bulkSerialSearchResult.next(bulkSerialSearch)
  }

  public downloadExcelFile(data: BlobPart, filename: string) {
    const blob: Blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url: string = window.URL.createObjectURL(blob);
    const link: HTMLAnchorElement = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${filename}.xlsx`); // or any other filename you want
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  disableBrowserNavigation(): void {
    this.browserBackSubcription$ = this.culibrary.disableBrowserBack().subscribe((_) => {
      history.pushState(null, '');
      this.culibrary.openBrowserNavigationPopup();
    });
  }

  setDestinationPage(urlPath: string) {
    this.culibrary.setDestinationPage(urlPath);
  }

  cleanUp(): void {
    this.browserBackSubcription$.unsubscribe();
  }

  getRecentSearches(): Observable<RecentSearch[]> {
    return this.apiService.get(this.config.GET_RECENT_SEARCH_INFO);
  }
  getRecentSearchList(recentSearcheListValue:RecentSearch[]){
    this.recentSearchList.next(recentSearcheListValue);
  }
  public getBulkSearchDownlaodTemplate(value:string): Observable<Blob> {
    const request = `${this.config.GET_BULK_SEARCH_DOWNLOAD_TEMPLATE}?bulkUploadType=${value}`;
    return this.apiService.getBlob(request);
  }

  public postBulkSearchUpload(searchField: string, file: File): Observable<BulkSerialSearchResult> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('searchField', searchField);
    return this.apiService.post<BulkSerialSearchResult>(`${this.config.POST_BULK_SEARCH_UPLOAD}`, formData)
      .pipe(map(searchResults => ({
        ...searchResults,
        validSerialCards: {
          ...searchResults.validSerialCards,
          serialCards: searchResults.validSerialCards?.serialCards.map(sc => this.restResponseToFragments(sc))
        }
      } satisfies BulkSerialSearchResult)));
  }

  public getMFGLocations(): Observable<MfgLoction[]> {
    return this.apiService.get(this.config.GET_MFG_LOCATIONS);
  }

  getOffices(): Observable<Office[]> {
    return this.apiService.getStaticData(this.config.GET_OFFICE_LIST_API);
  }

  public getNoteTypes(): Observable<string[]> {
    return this.apiService.get(this.config.GET_NOTE_TYPES);
  }
  public getDocuments(documentList: ConstructionPayload): Observable<DocumentResponse> {
    return this.apiService.post<DocumentResponse>(this.config.GET_DOCUMENTLIST, documentList);
  }

  public getDocumentFile(document: Document): Observable<Blob> {
    return this.apiService.post<File>(this.config.GET_DOCUMENT_FILE, document, { responseType: 'blob' });
  }

  public getDocumentFromQADocs(documentInfo:ConstructionPayload): Observable<DocumentURL[]> {
    return this.apiService.post(this.config.GET_DOCUMENT_FROM_QADOCS, documentInfo);
  }

  public getFlexSerialCard(flexSeriaCard: FlexSerialCard): Observable<HttpResponse<Blob>> {
    return this.apiService.post(this.config.GET_FLEX_SERIAL_CARD, flexSeriaCard, { responseType: 'blob', observe: 'response', });
  }
  public getFlexSerialCardReport(request:any): Observable<HttpResponse<Blob>> {
    return this.apiService.post(this.config.GET_FLEX_SERIAL_CARD_REPORT,request,{ responseType: 'blob', observe: 'response', });
  }

  public downloadSerialCardPdf(data: BlobPart, filename: string, type: string) {
    const blob: Blob = new Blob([data], { type: type });
    const url = window.URL.createObjectURL(blob); // Create a URL for the blob
    const anchor = document.createElement('a'); // Create an <a> element
    anchor.href = url;
    let extension = '';
    if (type === AppType.PDF) {
    extension = 'pdf';
    } else if (type === AppType.ZIP) {
    extension = 'zip';
    }
    anchor.download = `${filename}.${extension}`; // Set the filename
    anchor.click(); // Trigger the download
    window.URL.revokeObjectURL(url); // Clean up the URL object
  }
  downloadSerialCard(serialNumber: string[], docType:number, serialReportCardType: number): Observable<any> {
    const serialCard = new FlexSerialCard();
    serialCard.serialNumber = serialNumber;
    serialCard.documentType = docType;
    serialCard.serialReportCardType = serialReportCardType;
    return this.getFlexSerialCardReport(serialCard);
  }
  public getSPIRExcelReport(serialNumbers: { serialNumber: string[] }): Observable<BlobPart> {
    return this.apiService.post(this.config.GET_SPIREXCELREPORT, serialNumbers, { responseType: 'blob' });
  }
  public getFieldChanges(fieldChangeRequest: FieldChangeRequest): Observable<FieldChange[]> {
    return this.apiService.post(this.config.GET_FIELD_CHANGE,fieldChangeRequest)
  }

  public getPartsDetails(request: { partNumbers: string}): Observable<AddPartsInfo> {
    return this.apiService.post(this.config.GET_PARTS_DETAILS, request);
  }

  public getPackingList(request: PackingListPayload): Observable<Blob> {
    return this.apiService.post<File>(this.config.GET_PACKING_LIST, request, { responseType: 'blob' });
  }

  public getProductsEmStringData(request: CompareSerialPayload): Observable<ProductsEmstringResponse> {
    return this.apiService.post(this.config.GET_PRODUCTS_EMSTRING_DATA,request);
  }

  private readonly compare = new BehaviorSubject<number>(1);
  sendCompare(val:number) {
    this.compare.next(val)
  }
  getCompare(): Observable<number> {
    return this.compare.asObservable();
  }

  public getComparedInfo(request: CompareAPIPayload): Observable<any> {
    return this.apiService.post(this.config.GET_COMPARED_INFO, request);
  }

  public getSerialCompareExport(request: CompareAPIPayload): Observable<Blob> {
    return this.apiService.post(this.config.GET_SERIAL_COMPARE_EXCEL_EXPORT, request, { responseType: 'blob' });
  }
}
