import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { NgModule } from '@angular/core';
import { ApolloClientOptions, FieldMergeFunction, InMemoryCache, NormalizedCacheObject } from '@apollo/client/core';
import { environment } from 'src/environments/environment';
import possibleTypesResultData from '../graphql/fragments.generated';
import { StrictTypedTypePolicies } from 'src/graphql/apollo-helpers.generated';

const mergeDateOnlyField: FieldMergeFunction<Date | null, string | null> = (_existingDate, dateString) => {
  if(!dateString) {
    return null;
  }
  const dateObj = new Date(Date.parse(dateString));
  // This is to put the date into a local date (aka "Wall date")
  dateObj.setMinutes(dateObj.getMinutes() + dateObj.getTimezoneOffset());
  return dateObj;
}

const typePolicies: StrictTypedTypePolicies = {
  Order: {
    fields: {
      orderDate: {
        merge: mergeDateOnlyField
      }
    }
  },
  SerialCard: {
    keyFields: ['serialNumber'],
    fields: {
      shipDate: {
        merge: mergeDateOnlyField
      }
    }
  },
  WarehouseInventory: {
    fields: {
      availableShipDate: {
        merge: mergeDateOnlyField
      }
    }
  }
}

export function createApollo(httpLink: HttpLink): ApolloClientOptions<NormalizedCacheObject> {
  return {
    link: httpLink.create({ 
      uri: environment.graphUrl,
    }),
    cache: new InMemoryCache({
      possibleTypes: possibleTypesResultData.possibleTypes,
      typePolicies
    }),
  };
}

@NgModule({
  exports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink],
    },
  ],
})
export class GraphQLModule {}
