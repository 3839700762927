import { ApplyType, CallBackAction, ProductFamily } from "src/app/shared/enums/buildassembly";
import { Assignment, ConfirmationPopUp, Constraint, Component as ConComponent, Restriction, EM, SpecialInfo, BreakRule, ErrorInfo, ConflictUI, Session, Product } from "src/app/pages/tag-workspace/store/models/build-assembly/buildassembly";
import { Price, PricingHeader, Products } from "src/app/shared/models/price";
import { ItemIdentifier } from "src/app/shared/models/project-worksapce/itemidentifier";
import { SaveValveSizingRequest } from "src/app/pages/tag-workspace/store/models/sizevalve/save.valvesize.request";
import { Conflict } from "./conflict";
import { MROMessages } from "src/app/shared/models/mro/attribute-error-msg";
import { SaveActuatorSizingRequest } from "../actuator/save.actuatorsizing.request";

export class Construction {
    itemIdentifier: ItemIdentifier;
    activeComponentGuid?: string;
    assemblyId: string;
    shortDescription?: string;
    longDescription?: string;
    longDescriptionOverride?: string;
    components: ConComponent[];
    assignments: Assignment[];
    catalogAssignments: Assignment[];
    price: Price[];
    em: EM[];
    hasValve: boolean;
    hasActuator: boolean;
    hasRegulator: boolean;
    hasValveActuator: boolean;
    hasFactoryMounting: boolean;
    hasDesuperheater: boolean;

    regulatorFamily: ProductFamily;
    hasDirectOperatedRegulator: boolean;
    hasDirectOperatedReliefValve: boolean;
    hasPilotOperatedRegulator: boolean;
    hasPilotOperatedReliefValve: boolean;
    hasPilotOperatedRegulatorTemperature: boolean;
    hasTankBlanketingVaporRecovery: boolean;
    hasPressureLoadedRegulator: boolean;

    hasPositioner: boolean;
    hasController: boolean;
    hasIndicator: boolean;
    hasLevelSensor: boolean;
    hasTransducer: boolean;

    hasLevelController: boolean;
    hasPartialAssembly: boolean;
    hasLiquidLevelSwitch: boolean;
    hasReceiverController: boolean;

    productFamily: ProductFamily;
    effectivePriceDate?: Date;
    qty: number;
    completeGroupIds: string[];
    specialAttributes: Assignment[];
    constraints: Constraint;
    applyType: ApplyType;
    IsSpecialAssembly: boolean;
    products: Product[];

    restrictions: Restriction;
    valveSize: string;
    actuatorSize: string;

    isComplete: boolean;

    isBuildAssemblyModified: boolean;
    confirmationPopUp: ConfirmationPopUp;
    pricingHeader: PricingHeader;

    breakRules: Array<BreakRule[]>;
    error: ErrorInfo;
    specialInfo: SpecialInfo;
    conflict: Conflict;
    conflictUI: ConflictUI;
    tPConflictMessage: string | null;
    shortStrokeMessage: string | null;

    callbackAction: CallBackAction;
    isSpecialCallRequired: boolean;

    session: Session;

    hasSpecialConfig: boolean;
    hasSpecialPrice: boolean;

    saveValveSizingRequest: SaveValveSizingRequest | undefined;
    saveActuatorSizingRequest: SaveActuatorSizingRequest | undefined;
    mroMessages?: MROMessages;

    referenceNbr: number | null;
    lineNum: number | null;

    constructor() {
        this.itemIdentifier = new ItemIdentifier();
        this.isComplete = false;
        this.IsSpecialAssembly = false;
        this.assignments = [];
        this.catalogAssignments = [];
        this.products = [];
        this.components = [];
        this.constraints = new Constraint();
        this.em = [];
        this.price = [];

        this.productFamily = ProductFamily.None;
        this.regulatorFamily = ProductFamily.None;

        this.hasValve = false;
        this.hasActuator = false;
        this.hasValveActuator = false;
        this.hasDesuperheater = false;
        this.hasRegulator = false;

        this.hasPositioner = false;
        this.hasController = false;
        this.hasIndicator = false;
        this.hasLevelSensor = false;
        this.hasTransducer = false;

        this.hasLevelController = false;
        this.hasPartialAssembly = false;
        this.hasLiquidLevelSwitch = false;
        this.hasReceiverController = false;

        this.hasDirectOperatedRegulator = false;
        this.hasDirectOperatedReliefValve = false;
        this.hasPilotOperatedRegulator = false;
        this.hasPilotOperatedReliefValve = false;
        this.hasPilotOperatedRegulatorTemperature = false;
        this.hasTankBlanketingVaporRecovery = false;
        this.hasPressureLoadedRegulator = false;

        this.shortDescription = "";
        this.longDescription = "";
        this.longDescriptionOverride = "";
        this.isBuildAssemblyModified = false;
        this.confirmationPopUp = new ConfirmationPopUp();
        this.applyType = ApplyType.Load;
        this.assemblyId = "";
        this.specialAttributes = [];
        this.completeGroupIds = [];
        this.effectivePriceDate = new Date(); //Should come from Project level

        this.restrictions = this.getRestriction();
        this.constraints = new Constraint();
        this.pricingHeader = new PricingHeader();

        this.qty = 1;
        this.valveSize = "";
        this.actuatorSize = "";

        this.breakRules = [[]];
        this.specialInfo = new SpecialInfo();
        this.conflict = new Conflict();
        this.conflictUI = new ConflictUI();
    }

    getRestriction(): Restriction {
        let restriction = new Restriction();

        return restriction;
    }
}
