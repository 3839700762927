import { Component as ConComponent } from "src/app/pages/tag-workspace/store/models/build-assembly/buildassembly";

export class CatalogChanged {
    isCatalogLineChanged: boolean;
    catalogComponent: ConComponent | undefined;

    constructor() {
        this.isCatalogLineChanged = false;
        this.catalogComponent = undefined;
    }
};