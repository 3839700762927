import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-select',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './select.component.html',
  styleUrl: './select.component.scss',
})
export class SelectComponent {
  @Input() options: { label: string; value: string, cssClass?: string }[] = [];
  @Input() selectedValue: string = '';
  @Input() wrapperClasses: string = '';
  @Input() selectClasses: string = '';
  @Input() chevronClasses: string = '';
  @Input() name: string = '';
  @Input() showChevron: boolean = true;
  @Input() placeHolder: string = 'Please select';

  @Output() valueChange = new EventEmitter<string>();

  selectChevronDownIcon: string = 'assets/icons/selectChevronDown.svg';

  onChange(event: Event) {
    const selectedValue = (event.target as HTMLSelectElement).value;
    this.valueChange.emit(selectedValue);
  }

  getWrapperClasses() {
    return `relative mb-4 form-group last:mb-0 w-fit ${this.wrapperClasses}`;
  }

  getSelectClasses() {
    return `flex items-center px-3 padding-right py-2 text-sm bg-white border border-gray-300 rounded-lg outline-none appearance-none pr-14 text-neutral-700 font-inter cursor-pointer ${this.selectClasses}`;
  }

  getChevronClasses() {
    // Additional classes for chevron can be added here
    return `${this.chevronClasses}`;
  }

  noneSelected() {
    return !this.options.some(option => option.value.toString() === this.selectedValue);
  }
}
